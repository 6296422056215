import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Overview from '../components/Pages/Overview';
import FinancingDetail from '../components/Pages/FinancingDetail';
import MyFinancing from '../components/Pages/MyFinancing';
import ConnectionsWrapper from '../components/Pages/ConnectionsWrapper';
import Application from '../components/Pages/Application/Application';
import Offer from '../components/Pages/Offer';
import ApplicationTerms from '../components/Pages/Application/ApplicationTerms';
import PageNotFound from '../components/Pages/PageNotFound';
import {
  ExternalUserDto,
  LoansOverviewDto,
  PartnerConnectionDto,
  ActiveApplicationDto,
} from '../types/api';
import Settings from '../components/Pages/Settings';

interface ICustomerRoutesProps {
  customerId: string;
  currentUser: ExternalUserDto;
  partner: PartnerConnectionDto | null;
  loan: LoansOverviewDto | null;
  applicationData: ActiveApplicationDto | null;
  changePartner: (partner: PartnerConnectionDto) => void;
  selectCustomerHandler: (
    customerId: string,
    partner: PartnerConnectionDto | null
  ) => void;
  updateApplicationData: (
    application: ActiveApplicationDto['application']
  ) => void;
}

const CustomerRoutes: FC<ICustomerRoutesProps> = ({
  customerId,
  currentUser,
  partner,
  loan,
  applicationData,
  changePartner,
  selectCustomerHandler,
  updateApplicationData,
}) => {
  return (
    <Routes>
      <Route
        path="/:customerId/overview"
        element={
          <Overview
            customerId={customerId}
            currentUser={currentUser}
            partner={partner!}
            loanActive={loan! && loan.active!}
            loanHistory={loan && loan.previous}
            applicationData={applicationData}
            changePartner={changePartner}
          />
        }
      />

      <Route
        path="/:customerId/my-financing/:id"
        element={
          <FinancingDetail
            history={loan && loan.previous}
            customerId={customerId}
          />
        }
      />

      <Route
        path="/:customerId/my-financing"
        element={
          <MyFinancing
            loanActive={loan && loan.active}
            loanHistory={loan && loan.previous}
            selectCustomerHandler={() =>
              selectCustomerHandler(customerId, partner!)
            }
            customerId={customerId}
          />
        }
      />

      <Route
        path="/:customerId/connections"
        element={<ConnectionsWrapper customerId={customerId} />}
      />
      <Route
        path="/:customerId/connections/:step?/:state?"
        element={<ConnectionsWrapper customerId={customerId} />}
      />
      <Route
        path="/connections/shopify-connected/:flowId?/:tenantId?/:sourceId?"
        element={<ConnectionsWrapper doConnectShopify customerId={customerId}/>}
      />
      <Route
        path="/:customerId/settings"
        element={<Settings currentUser={currentUser} />}
      />
      <Route
        path="/:customerId/application/:step?/:state?"
        element={
          <Application
            customerId={customerId}
            applicationData={applicationData!}
            currentUser={currentUser}
            partner={partner}
            updateApplicationData={updateApplicationData}
          />
        }
      />

      <Route
        path="/:customerId/offer/:partnerName/:merchantID"
        element={
          <Offer
            currentUser={currentUser}
            customerId={customerId}
            selectCustomerHandler={selectCustomerHandler}
          />
        }
      />

      <Route
        path="/:customerId/entry/:partnerName"
        element={
          <Offer
            currentUser={currentUser}
            customerId={customerId}
            selectCustomerHandler={selectCustomerHandler}
          />
        }
      />

      <Route
        path="/"
        element={<Navigate to={`/${customerId}/overview`} replace />}
      />

      <Route
        path="/signin"
        element={<Navigate to={`/${customerId}/overview`} replace />}
      />
      <Route
        path="/signin/lead/:lead"
        element={<Navigate to={`/${customerId}/overview`} replace />}
      />
      <Route
        path="/signup"
        element={<Navigate to={`/${customerId}/overview`} replace />}
      />
      <Route
        path="/signup/lead/:lead"
        element={<Navigate to={`/${customerId}/overview`} replace />}
      />

      <Route
        path="/:customerId/applications/:id/accept-changed-terms"
        element={<ApplicationTerms customerId={customerId} />}
      />
      <Route
        path="/:customerId/applications/:id/accept-changed-terms/success"
        element={<ApplicationTerms customerId={customerId} />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default CustomerRoutes;
