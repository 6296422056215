import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Button from '@mui/material/Button';

interface IBackButtonProps {
  onClick: () => void;
}

export const BackButton: FC<IBackButtonProps> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button
      type="button"
      onClick={onClick}
      variant="contained"
      size="small"
      color="inherit"
      startIcon={<KeyboardArrowLeftIcon color="primary" />}
      sx={{
        borderRadius: '3rem',
        width: { xs: 'auto', sm: '120px' },
        height: '35px',
        alignSelf: 'flex-end',
        background: 'white',
        border: { xs: 'none', sm: '1px solid #DEDEDE' },
      }}
    >
      {t('application-button-back')}
    </Button>
  );
};
