import { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

interface IRegistrationProgressBarProps {
  progressStep: number;
  isLastStep: boolean;
}

export const RegistrationProgressBar: FC<IRegistrationProgressBarProps> = ({
  isLastStep,
  progressStep,
}) => {
  return (
    <Box
      sx={{
        background: 'white',
        border: '1px solid #DEDEDE',
        padding: '1rem',
        marginTop: '1rem',
        borderRadius: '25px',
        fontFamily: 'Arial',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress
            variant="determinate"
            value={progressStep}
            color="secondary"
          />
        </Box>
        {isLastStep && (
          <Box
            sx={{
              minWidth: 35,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '.25rem',
            }}
          >
            <CheckCircleIcon color="secondary" />
          </Box>
        )}
      </Box>
    </Box>
  );
};
