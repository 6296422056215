import React, { FC } from 'react';
import { useMediaQuery } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

interface IPartnerBannerProps {
  partnerName?: string;
}

const PartnerBanner: FC<IPartnerBannerProps> = ({ partnerName }) => {
  // Check if the screen size is mobile
  const isMobile = useMediaQuery('(max-width: 650px)');
  const imageName = isMobile ? 'Mobile' : 'Web';
  let imagePath = '';
  const { isAuthenticated } = useAuth0();

  try {
    imagePath = require(`../../assets/partners/banners/${partnerName}${imageName}.png`);
  } catch (error) {
    // Handle the error by setting imagePath to null or an empty string
    imagePath = '';
  }

  return imagePath && !isAuthenticated ? (
    <img
      src={imagePath}
      alt={`${partnerName} banner`}
      style={{
        width: '80%',
        height: 'auto',
        display: 'block',
        margin: '0 auto 20px auto',
      }}
    />
  ) : null;
};

export default PartnerBanner;
