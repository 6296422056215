import MuiTable, { TableProps } from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper, { PaperProps } from '@mui/material/Paper';

import { styled } from '@mui/material/styles';
import styles from './Table.module.scss';
import { FC } from 'react';

interface ITableProps {
  header: string;
  columns: Array<any>;
  rows: Array<any>;
  minWidth?: number;
  footer?: string;
}

const Table: FC<ITableProps> = ({
  columns,
  header,
  rows,
  footer,
  minWidth,
}) => {
  const TablePaper = styled((props: PaperProps) => <Paper {...props} />)(
    () => ({
      backgroundColor: '#F8F8FB',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.04)',
      borderRadius: '13px',
      padding: '1rem 2rem',
    })
  );

  const TableStyled = styled((props: TableProps) => <MuiTable {...props} />)(
    () => ({
      '.MuiTableCell-root': {
        padding: '.5rem',
        color: '#747474',
        fontWeight: '300',
      },
      '.MuiTableHead-root .MuiTableCell-root': {
        fontWeight: '500',
        borderBottom: 0,
      },
    })
  );

  return (
    <div className={styles['table-wrapper']}>
      {header && <h2>{header}</h2>}

      <div className={styles.table}>
        <TableContainer component={TablePaper}>
          <TableStyled
            sx={{ minWidth: minWidth || 300 }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                {columns.map((c, index) => (
                  <TableCell key={index} align={c.align}>
                    {c.title}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((r) => (
                <TableRow
                  key={r.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  {columns.map((c, i) => (
                    <TableCell key={i} align={c.align}>
                      {r[c.name]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </TableStyled>

          {footer && <div className={styles.footer}>{footer}</div>}
        </TableContainer>
      </div>
    </div>
  );
};

export default Table;
