import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from '../../UI/Button';
import styles from './Application.module.scss';
import { OfferDto } from '../../../types/api';
import axios from '../../../utils/axios';
import { useAppDispatch } from '../../../selectors/selectors';
import { setCurrentCustomerOffer } from '../../../store/slices/offerSlice';

interface IApplicationDoneProps {
  customerId: string;
}

const ApplicationDone: FC<IApplicationDoneProps> = ({ customerId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const updateApplication = async () => {
    try {
      const { data } = await axios.get<OfferDto>(
        `offers/active/customer/${customerId}`
      );
      dispatch(setCurrentCustomerOffer(data));

      navigate(`/${customerId}/overview`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="gradient info">
        <h1>
          <span>{t('application-done-title')}</span>
        </h1>
      </div>

      <div className={styles['body']}>
        <p>{t('application-done-txt')}</p>

        <div className={styles.buttons}>
          <Button
            className="application"
            onClick={updateApplication}
            id="app_finished"
          >
            {t('application-button-back-overview')}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ApplicationDone;
