import { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Grid from '@mui/material/Unstable_Grid2';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';

import { BackButton } from './BackButton';

interface IRegistrationProgressBarMobileProps {
  progressStep: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  onClick: () => void;
  displayBackButton: boolean;
}

export const RegistrationProgressBarMobile: FC<
  IRegistrationProgressBarMobileProps
> = ({ isFirstStep, isLastStep, progressStep, onClick, displayBackButton }) => {
  return (
    <Grid container width="100%">
      <Grid sm={12} pr={0} pl={0} sx={{ width: '100%' }}>
        <Box
          sx={{
            background: 'white',
            border: '1px solid #DEDEDE',
            padding: { xs: '0', sm: '1rem' },
            marginTop: '1rem',
            borderRadius: '25px',
            fontFamily: 'Arial',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box
              sx={{
                width: '100%',
                mr: !isFirstStep ? 1 : 0,
                display: 'flex',
                justifyContent: displayBackButton ? 'flex-start' : 'center',
                alignItems: 'center',
                minHeight: '2.2rem',
              }}
            >
              {displayBackButton && <BackButton onClick={onClick} />}
              <LinearProgress
                variant="determinate"
                value={progressStep}
                color="secondary"
                sx={{ width: displayBackButton ? '85%' : '95%' }}
              />
            </Box>
            {isLastStep && (
              <Box
                sx={{
                  minWidth: 35,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '.25rem',
                }}
              >
                <CheckCircleIcon color="secondary" />
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
