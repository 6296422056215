import { ReactNode, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider } from '@auth0/auth0-react';

interface IAuth0ProviderWithHistoryProps {
  children: ReactNode;
}

const Auth0ProviderWithHistory: FC<IAuth0ProviderWithHistoryProps> = ({
  children,
}) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? '';
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? '';
  const audience = process.env.REACT_APP_API_AUDIENCE ?? '';
  const tenant = process.env.REACT_APP_AUTH0_TENANT ?? '';
  const redirectUri = window.location.origin;
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      audience={audience}
      redirectUri={redirectUri}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      useCookiesForTransactions={true}
      cacheLocation="localstorage"
      authorizationParams={{
        screen_hint: 'login',
        redirect_uri: tenant,
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
