import { useAppSelector } from '../../selectors/selectors';

const Dialogs = () => {
  const { dialogs } = useAppSelector((state) => state.dialogs);

  return (
    <>
      {dialogs.map((dialog) => {
        const { component: Dialog, props } = dialog as any;
        return <Dialog key={dialog.id} {...props} />;
      })}
    </>
  );
};

export default Dialogs;