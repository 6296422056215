import { FC, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import Dialog from '../UI/Dialog';
import Button from '../UI/Button';
import TextEdit from '../UI/TextEdit';

import axios from '../../utils/axios';
import { useLoading } from '../../context/loading-context';
import { useErrorHandler } from '../../context/error-handler-context';
import { COLORS } from '../../constants/colors';

interface INewConnectionDialogProps {
  onClose: (value: any) => void;
  open: boolean;
  customerId: string;
}

const NewConnectionDialog: FC<INewConnectionDialogProps> = ({
  customerId,
  onClose,
  open,
}) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  const [platformName, setPlatformName] = useState('');
  const [platformUrl, setPlatformUrl] = useState('');
  const [comment, setComment] = useState('');

  const handleClose = (success = false) => {
    resetDialog();
    onClose(success);
  };

  const resetDialog = () => {
    setPlatformName('');
    setPlatformUrl('');
    setComment('');
  };

  const submitHandler = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = {
      customerId,
      platformName,
      platformUrl,
      comment,
    };

    setLoading(true);
    axios
      .post(
        `partner-connections/customers/${customerId}/new-partner-requests`,
        data
      )
      .then((res) => {
        setLoading(false);
        handleClose(true);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  return (
    <Dialog
      open={open}
      handleClose={handleClose}
      title={t('connections-dialog-title')}
    >
      <form onSubmit={submitHandler}>
        <DialogContent
          sx={{
            p: '0.5rem 5rem 0.5rem 5rem !important',
            color: COLORS.text,
            fontWeight: 700,
            fontSize: '1.2rem',
            lineHeight: 'initial',
            fontFamily: '"Inter", sans-serif',
          }}
        >
          <DialogContentText>{t('connections-dialog-info')}</DialogContentText>

          <TextEdit
            autoFocus={true}
            id="platformName"
            label={t('connections-dialog-form-name')}
            value={platformName}
            required
            onChange={(value: string) => {
              setPlatformName(value);
            }}
          />
          <TextEdit
            id="platformUrl"
            label={t('connections-dialog-form-web')}
            value={platformUrl}
            required
            onChange={(value: string) => {
              setPlatformUrl(value);
            }}
          />
          <TextEdit
            id="comment"
            placeholder={t('connections-dialog-form-description')}
            fullWidth={true}
            value={comment}
            multiline={true}
            rows={4}
            noline
            onChange={(value: string) => {
              setComment(value);
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: '0rem 5rem 2rem 5rem' }}>
          <Button type="submit" className="gradient dialog">
            <span>{t('connections-dialog-form-send')}</span>
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewConnectionDialog;
