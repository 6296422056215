import { useState, useEffect, useCallback, FC } from 'react';
import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent, SelectProps } from '@mui/material/Select';

import { useLoading } from '../../../context/loading-context';
import { useErrorHandler } from '../../../context/error-handler-context';
import axios from '../../../utils/axios';
import { formatMoney, formatDate } from '../../../utils/format';
import { formatDate as fd } from '../../../utils/date';

import Table from '../../Sections/Table';
import PieChart from '../../Sections/PieChart';

import { styled } from '@mui/material/styles';
import styles from './PartnerFinancing.module.scss';
import { CommissionReportEntry } from '../../../types/api';

interface IPartnerFinancingProps {
  customerId: string;
}

const PartnerFinancing: FC<IPartnerFinancingProps> = ({ customerId }) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  const [type, setType] = useState('all');
  const [period, setPeriod] = useState('all');
  const [tableData, setTableData] = useState<Array<CommissionReportEntry>>([]);

  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();

  const periods = [{ date: 'all', dateTxt: 'Vše' }];
  for (let i = 0; i < 12; i++) {
    periods.push({
      date: `${year}-${('0' + (month + 1)).slice(-2)}-01`,
      dateTxt: `${('0' + (month + 1)).slice(-2)}/${year}`,
    });

    if (month === 0) {
      month = 11; // dec
      year--;
    } else {
      month--;
    }
  }

  const SelectStyled = styled((props: SelectProps) => <Select {...props} />)(
    () => ({
      '.MuiSelect-select': {
        padding: '0.5rem 1.5rem !important',
        color: '#838383',
        fontFamily: '"Inter", sans-serif',
        textAlign: 'center',
      },
      '.MuiSvgIcon-root': {
        display: 'none',
      },
    })
  );

  const handleChangeType = (event: SelectChangeEvent<unknown>) => {
    setType(event.target.value as string);
  };

  const handleChangePeriod = (event: SelectChangeEvent<unknown>) => {
    setPeriod(event.target.value as string);
  };

  const loadData = useCallback(() => {
    setLoading(true);
    const filter = type === 'all' ? '' : `/filter/${type}`;
    const date = period === 'all' ? '' : `/date/${period}`;
    axios
      .get(`loans/partners/${customerId}/commissions${filter}${date}`)
      .then((res) => {
        console.log(res.data);

        setLoading(false);
        setTableData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [period, setError, setLoading, type, customerId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = [
    { name: 'loanExternalRef', title: 'ID', align: 'left' },
    {
      name: 'customerName',
      title: t('partner-financing-table-customer'),
      align: 'center',
    },
    {
      name: 'loanPrincipal',
      title: t('partner-financing-table-principal'),
      align: 'right',
    },
    {
      name: 'loanFee',
      title: t('partner-financing-table-fee'),
      align: 'right',
    },
    {
      name: 'loanDisbursedAt',
      title: t('partner-financing-table-disbursed-at'),
      align: 'center',
    },
    {
      name: 'amount',
      title: t('partner-financing-table-amount'),
      align: 'right',
    },
    {
      name: 'effectiveAt',
      title: t('partner-financing-table-effective-at'),
      align: 'center',
    },
    {
      name: 'paidAt',
      title: t('partner-financing-table-paid-at'),
      align: 'center',
    },
    {
      name: 'loanPaidPerc',
      title: t('partner-financing-table-status'),
      align: 'left',
    },
  ];

  const rows = tableData
    ? tableData.map((reportEntry) => {
        return {
          id: reportEntry.id,
          loanExternalRef: reportEntry.loanExternalRef,
          customerName: reportEntry.customerName,
          loanPrincipal: formatMoney(reportEntry.loanPrincipal),
          loanFee: formatMoney(reportEntry.loanFee),
          loanDisbursedAt: formatDate(reportEntry.loanDisbursedAt),
          amount: formatMoney(reportEntry.amount),
          effectiveAt: fd(reportEntry.effectiveAt, 'MM/YYYY'),
          paidAt: fd(reportEntry.paidAt, 'MM/YYYY'),
          loanPaidPerc: (
            <PieChart type="table" value={reportEntry.loanPaidPerc} />
          ),
        };
      })
    : [];

  return (
    <>
      <div className={styles['filter-wrapper']}>
        <div className={styles.filter}>
          <div className={styles.criterion}>
            <label>{t('partner-financing-filter')}</label>
            <SelectStyled value={type} onChange={handleChangeType} variant='outlined'>
              <MenuItem value={'all'}>
                {t('partner-financing-filter-all')}
              </MenuItem>
              <MenuItem value={'disbursed'}>
                {t('partner-financing-filter-disbursed')}
              </MenuItem>
              <MenuItem value={'effective'}>
                {t('partner-financing-filter-effective')}
              </MenuItem>
              <MenuItem value={'paid'}>
                {t('partner-financing-filter-paid')}
              </MenuItem>
            </SelectStyled>
          </div>

          <div className={styles.criterion}>
            <label>{t('partner-financing-filter-period')}</label>
            <SelectStyled value={period} onChange={handleChangePeriod} variant='outlined'>
              {periods.map((p) => (
                <MenuItem key={p.date} value={p.date}>
                  {p.dateTxt}
                </MenuItem>
              ))}
            </SelectStyled>
          </div>
        </div>
      </div>
      <div className={styles['content-wrapper']}>
        <Table
          header={t('partner-financing-title')}
          columns={columns}
          rows={rows}
          minWidth={1300}
        />
      </div>
    </>
  );
};

export default PartnerFinancing;
