import { configureStore } from '@reduxjs/toolkit';
import currentUserReducer from './store/slices/currentUserSlice';
import snackbarsReducer from './store/slices/snackbarsSlice';
import dialogsReducer from './store/slices/dialogsSlice';
import currewntCustomerReducer from './store/slices/currentCustomerSlice';
import offerReducer from './store/slices/offerSlice';
import applicationReducer from './store/slices/applicationSlice';
import languageReducer from './store/slices/languageSlice';

export const store = configureStore({
  reducer: {
    currentUser: currentUserReducer,
    snackbars: snackbarsReducer,
    dialogs: dialogsReducer,
    currentCustomer: currewntCustomerReducer,
    offer: offerReducer,
    application: applicationReducer,
    language: languageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
