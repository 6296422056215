import { FC } from 'react';
import HotglueConfig from '@hotglue/widget';
import Connections from './Connections';

const envId = process.env.REACT_APP_HOTGLUE_ENV ?? '';
const apiKey = process.env.REACT_APP_HOTGLUE_API_KEY ?? '';

interface IConnectionsWrapperProps {
  customerId?: string;
  doConnectShopify?: boolean;
}

const ConnectionsWrapper: FC<IConnectionsWrapperProps> = ({
  customerId,
  doConnectShopify
}) => {
  return (
    <HotglueConfig
      config={{
        apiKey,
        envId,
      }}
    >
      <Connections customerId={customerId}  doConnectShopify={doConnectShopify}/>
    </HotglueConfig>
  );
};

export default ConnectionsWrapper;
