import React, { useEffect, SyntheticEvent } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar, SnackbarKey } from 'notistack';
import { RootState } from '../../store';
import { removeSnackbar } from '../../store/slices/snackbarsSlice';
import { useAppDispatch, useAppSelector } from '../../selectors/selectors';

const Snackbars: React.FC = () => {
  const { snackbars } = useAppSelector((state: RootState) => state.snackbars);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    snackbars.forEach((snackbar) => {
      const { id, message, variant } = snackbar;
      const snackbarKey: SnackbarKey = id.toString();

      const action = (key: SnackbarKey) => (
        <IconButton
          onClick={() => {
            dispatch(removeSnackbar({ id }));
            closeSnackbar(key);
          }}
        >
          <CloseIcon sx={{ color: 'white' }} />
        </IconButton>
      );

      const onClose = (
        event: SyntheticEvent<any, Event> | null,
        reason: string
      ) => {
        if (reason === 'clickaway') {
          return;
        }
        dispatch(removeSnackbar({ id }));
      };

      enqueueSnackbar(message, {
        key: snackbarKey,
        variant,
        action,
        onClose,
      });
    });
  }, [snackbars, dispatch, closeSnackbar, enqueueSnackbar]);

  return null;
};

export default Snackbars;
