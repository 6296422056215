import { useEffect, FC } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useLoading } from '../../../context/loading-context';
import { useErrorHandler } from '../../../context/error-handler-context';
import axios from '../../../utils/axios';
import Card from '../../UI/Card';
import styles from './ApplicationTerms.module.scss';
import { RobotImage } from '../../UI/RobotImage';

interface IApplicationTermsProps {
  customerId: string;
}

const ApplicationTerms: FC<IApplicationTermsProps> = ({ customerId }) => {
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  const location = useLocation();
  const { pathname } = location;
  let splitLocation = pathname.split('/');
  splitLocation = splitLocation.filter(Boolean);

  useEffect(() => {
    if (splitLocation.length === 5 && splitLocation[4] === 'success') return;

    setLoading(true);
    axios
      .post(`applications/${id}/accept-changed-terms`, {})
      .then((res) => {
        setLoading(false);
        navigate(
          `/${customerId}/applications/${id}/accept-changed-terms/success`
        );
      })
      .catch((err) => {
        setLoading(false);

        if (err && err.response && err.response.status === 422) {
          navigate(
            `/${customerId}/applications/${id}/accept-changed-terms/success`
          );
        } else {
          setError(err);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles['page-wrapper']}>
      {splitLocation.length === 5 && splitLocation[4] === 'success' && (
        <>
          <RobotImage />
          <div className={styles['card-wrapper']}>
            <Card>
              <h1>{t('application-terms-title')}</h1>
              {t('application-terms-txt')}
            </Card>
          </div>
        </>
      )}
    </div>
  );
};

export default ApplicationTerms;
