import { useTranslation } from 'react-i18next';

import Card from '../UI/Card';

import styles from './PageNotFound.module.scss';
import { RobotImage } from '../UI/RobotImage';

const PageNotFound = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['not-found-wrapper']}>
      <RobotImage />

      <div className={styles.info}>
        <h1>{t('404-title')}</h1>
        <Card>{t('404-info')}</Card>
      </div>
    </div>
  );
};

export default PageNotFound;
