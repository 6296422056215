import { useState, useEffect, useRef, FC } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '@mui/material/Tooltip';

import { formatMoney } from '../../../utils/format';
import { getCeilNum } from '../../../utils/number';
import { formatDate as fd } from '../../../utils/date';

import PartnerFilter from '../../Sections/Partner/PartnerFilter';
import { default as Commissions } from '../../Sections/Partner/PartnerCommissions';
import styles from './PartnerOverview.module.scss';
import { PartnerLoansOverviewDto, ExternalUserDto } from '../../../types/api';

interface IPartnerOverviewProps {
  currentUser: ExternalUserDto;
  partnerOverview: PartnerLoansOverviewDto;
}

const PartnerOverview: FC<IPartnerOverviewProps> = ({
  currentUser,
  partnerOverview,
}) => {
  const { t } = useTranslation();
  const chart = useRef<HTMLDivElement | null>(null);
  const [hasScroll, setHasScroll] = useState(false);

  const monthlyInCirculation = partnerOverview.monthlyInCirculation
    ? partnerOverview.monthlyInCirculation
    : [];

  const monthlyDisbursed = partnerOverview.monthlyDisbursed
    ? partnerOverview.monthlyDisbursed
    : [];

  const chartOneAmount = [
    ...monthlyInCirculation.map((c) => +c.amount),
    ...monthlyDisbursed.map((d) => +d.amount),
  ].filter(Number);

  const chartOnemax = Math.max(...chartOneAmount);
  const chartOneMaxHeight = 250;
  const chartOneCeilMax = getCeilNum(chartOnemax);

  const chartOneData = monthlyInCirculation.map((c) => {
    return {
      date: c.date,
      circulation: +c.amount || 0,
      disbursed: null as number | null,
    };
  });
  monthlyDisbursed.forEach((d) => {
    const data = chartOneData.find((i) => i.date === d.date);
    if (data && data.disbursed) data.disbursed = +d.amount;
  });

  useEffect(() => {
    function handleResize() {
      if (chart.current) {
        setHasScroll(chart.current.scrollWidth > chart.current.clientWidth);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <>
      <PartnerFilter currentUser={currentUser} />

      <section className={styles.section}>
        <div className={`${styles['info-box']} ${styles['no-border']}`}>
          <div className={styles.info}>
            <span>
              <span className={styles.bold}>
                {t('partner-overview-money-volume')}{' '}
              </span>
              {t('partner-overview-year')}
            </span>
            <span className={`${styles.rounded} ${styles.alt2}`}>
              {formatMoney(partnerOverview.totalInCirculation)}
            </span>
          </div>

          <div className={styles.info}>
            <span>
              <span className={styles.bold}>
                {t('partner-overview-provided-alt')}{' '}
              </span>
              {t('partner-overview-total')}
            </span>
            <span className={`${styles.rounded} ${styles.alt4}`}>
              {formatMoney(partnerOverview.totalDisbursed)}
            </span>
          </div>

          <div className={styles.info}>
            <span>
              <span className={styles.bold}>
                {t('partner-overview-average')}{' '}
              </span>
              {t('partner-overview-financing')}
            </span>
            <span className={`${styles.rounded} ${styles.alt}`}>
              {formatMoney(partnerOverview.disbursedPrincipalAvg)}
            </span>
          </div>
        </div>

        <div
          className={`${styles['chart-wrapper']} ${
            hasScroll ? styles['has-scroll'] : ''
          }`}
        >
          <div className={styles['y-axis']}>
            <span>{t('partner-overview-how-much')}</span>
          </div>

          <div ref={chart} className={styles.chart}>
            {chartOneData.map((i, key) => {
              const cHeight =
                !i.circulation || i.circulation === 0
                  ? chartOneMaxHeight
                  : (i.circulation * chartOneMaxHeight) / chartOneCeilMax;

              const dHeight =
                !i.disbursed || i.disbursed === 0
                  ? chartOneMaxHeight
                  : (i.disbursed * chartOneMaxHeight) / chartOneCeilMax;

              return (
                <div className={styles['line-wrapper']} key={key}>
                  <div className={styles.lines}>
                    <Tooltip
                      title={
                        <span
                          style={{
                            display: 'inline-block',
                            textAlign: 'center',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {formatMoney(i.circulation)}
                        </span>
                      }
                    >
                      <div
                        className={styles.line}
                        style={{ height: `${chartOneMaxHeight}px` }}
                      >
                        <div
                          className={`${styles.circulation} ${
                            (!i.circulation || i.circulation === 0) &&
                            styles.zero
                          }`}
                          style={{ height: `${cHeight >= 0 ? cHeight : 0}px` }}
                        ></div>
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={
                        <span
                          style={{
                            display: 'inline-block',
                            textAlign: 'center',
                            whiteSpace: 'pre-line',
                          }}
                        >
                          {formatMoney(i.disbursed)}
                        </span>
                      }
                    >
                      <div
                        className={styles.line}
                        style={{ height: `${chartOneMaxHeight}px` }}
                      >
                        <div
                          className={`${styles.disbursed} ${
                            (!i.disbursed || i.disbursed === 0) && styles.zero
                          }`}
                          style={{ height: `${dHeight >= 0 ? dHeight : 0}px` }}
                        ></div>
                      </div>
                    </Tooltip>
                  </div>
                  {fd(i.date, 'MM/YYYY')}
                </div>
              );
            })}
          </div>

          <div className={styles['x-axis']}>
            <span className={styles.highlight}>
              {t('partner-overview-money-volume')}{' '}
            </span>
            {t('partner-overview-money-volume-new')}
          </div>
        </div>
      </section>

      <Commissions partnerOverview={partnerOverview} />
    </>
  );
};

export default PartnerOverview;
