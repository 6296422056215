import { ReactElement, ReactNode, FormEvent } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../constants/colors';
import { useScreenSize } from '../../../hooks';
import { registrationStepNames } from '../../../constants/registrationSteps';

type FormWrapperProps = {
  title: string;
  children: ReactNode;
  isLastStep?: boolean;
  footer?: string | ReactElement;
  buttonText?: string;
  currentStep: number;
  onSubmit: () => void;
  loading?: boolean;
  omitContinueButton?: boolean;
};

export function FormWrapper({
  title,
  children,
  footer,
  buttonText,
  isLastStep,
  currentStep,
  onSubmit,
  loading,
  omitContinueButton,
}: FormWrapperProps) {
  const { t } = useTranslation();
  const { isMediumScreen } = useScreenSize();
  const onSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <form
      onSubmit={onSubmitForm}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Box mt={3} display="flex" alignItems="center" flexWrap="wrap">
        <Box
          sx={{
            background: isLastStep ? COLORS.secondary : COLORS.primary,
            color: 'white',
            width: isLastStep ? '5rem' : '2.875rem',
            height: '25px',
            textAlign: 'center',
            fontWeight: 'bold',
            borderRadius: '0 1.094rem 1.094rem 0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: { xs: 'absolute', sm: 'unset' },
            left: [0, null, null],
            top: { xs: isLastStep ? '2.3rem' : 'unset' },
          }}
        >
          {isLastStep
            ? t('registration.registration-finished')
            : `${currentStep}.`}
        </Box>
        <Typography
          variant="h2"
          ml={3}
          sx={{
            fontSize: { xs: '1rem', sm: '1.75rem' },
            textAlign: 'left',
            mt: isLastStep ? (isMediumScreen ? '1rem' : '2rem') : 0,
          }}
          fontWeight="bold"
        >
          {title}
        </Typography>
      </Box>

      <Box
        sx={{
          padding: '1rem',
          margin: '.5rem',
          borderRadius: '.5rem',
          fontFamily: 'Arial',
        }}
      >
        {children}

        <Box
          sx={{
            marginTop: '2rem',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {loading ? (
            <LoadingButton loading variant="outlined" color="primary">
              {buttonText ? buttonText : t('application-button-continue')}
            </LoadingButton>
          ) : !omitContinueButton ? (
            <Button
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              disableFocusRipple
              disableRipple
              disableTouchRipple
              id={`step-${registrationStepNames[currentStep]}`}
            >
              {buttonText ? buttonText : t('application-button-continue')}
            </Button>
          ) : null}
        </Box>
        {!loading && footer ? (
          typeof footer === 'string' ? (
            <Typography sx={{ marginTop: '1rem' }}>{footer}</Typography>
          ) : (
            footer
          )
        ) : null}
      </Box>
    </form>
  );
}
