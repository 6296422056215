import { useState, FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import i18n from '../../i18n/config';

import footerLogo from '../../assets/footer-logo.svg';
import telIcon from '../../assets/icons/tel-icon.svg';
import emailIcon from '../../assets/icons/email-icon.svg';
import questionIcon from '../../assets/icons/question-icon.svg';

import styles from './Footer.module.scss';
import { useAppDispatch } from '../../selectors/selectors';
import { setAppLanguage } from '../../store/slices/languageSlice';
import { useSplitLocation } from '../../hooks';

interface IFooterProps {
  hideContacts?: boolean;
  transparent?: boolean;
  isFixedPosition?: boolean;
}

const Footer: FC<IFooterProps> = ({
  hideContacts,
  transparent,
  isFixedPosition,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const splitLocation = useSplitLocation();

  const isBuckaroo =
    splitLocation.includes('offer') && splitLocation.includes('Buckaroo');

  //@ts-ignore TODO update the library to fix typing issues
  const defLang = i18n.fpLangs.find((lang) => lang.code === i18n.language);
  const dispatch = useAppDispatch();

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setLanguage = (lang: string) => {
    if (lang && defLang.code !== lang) {
      dispatch(setAppLanguage(lang));
    }
    handleClose();
  };

  return (
    <footer
      className={`${transparent ? styles.transparent : ''} ${
        isFixedPosition ? styles.fixed : ''
      }`}
    >
      {process.env.REACT_APP_MULTILANG_ENABLED === 'TRUE' && (
        <Tooltip title={defLang.label}>
          <button className={styles.language} onClick={handleClick}>
            <img src={defLang.flag} alt={defLang.label} />
          </button>
        </Tooltip>
      )}

      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {/*//@ts-ignore TODO update the library to fix typing issues */}
        {i18n.fpLangs.map((lang) => {
          return (
            <MenuItem
              key={lang.code}
              selected={defLang.code === lang.code}
              onClick={() => setLanguage(lang.code)}
            >
              <img
                style={{ width: '24px' }}
                className={styles.language}
                src={lang.flag}
                title={lang.label}
                alt={lang.label}
              />
            </MenuItem>
          );
        })}
      </Popover>

      {!hideContacts && (
        <>
          <span>{t('footer-info')}</span>
          <div className={styles['contact-info']}>
            <img
              src={questionIcon}
              title={t('support-url-text')}
              alt={t('support-url-text')}
              className={styles['large-img']}
            />
            <a
              href={isBuckaroo ? t('support-url-buckaroo') : t('support-url')}
              target="_blank"
              rel="noreferrer"
            >
              {t('support-url-text')}
            </a>
          </div>
          <div className={styles['contact-info']}>
            <img
              src={telIcon}
              title={t('support-phone-number')}
              alt={t('support-phone-number')}
            />
            <a href={`tel:${t('support-phone-number')}`}>
              {t('support-phone-number')}
            </a>
          </div>
          <div className={styles['contact-info']}>
            <img
              src={emailIcon}
              title={t('support-email')}
              alt={t('support-email')}
            />
            <a href={`mailto:${t('support-email')}`}>{t('support-email')}</a>
          </div>
        </>
      )}

      <div className={styles.logo}>
        <img src={footerLogo} title="Footer logo" alt="Footer logo" />
      </div>
    </footer>
  );
};

export default Footer;
