import { useState, useEffect, useRef, FC } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '@mui/material/Tooltip';

import { formatDate, formatMoney, kFormatter } from '../../utils/format';
import { formatDate as fd } from '../../utils/date';
import { getCeilNum, getFloorNum } from '../../utils/number';
import styles from './Chart.module.scss';

interface IChartProps {
  insights: {
    previous: Record<string, any>;
    next: Record<string, any>;
  };
}

const Chart: FC<IChartProps> = ({ insights: { next, previous } }) => {
  const { t } = useTranslation();
  const chartOne = useRef<HTMLDivElement | null>(null);
  const chartTwo = useRef<HTMLDivElement | null>(null);
  const [chartOneVisibleMonths, setChartOneVisibleMonths] =
    useState<boolean>(false);
  const [chartTwoVisibleMonths, setChartTwoVisibleMonths] =
    useState<boolean>(false);

  const hasData = Boolean(
    Object.keys(next).length || Object.keys(previous).length
  );

  const all = hasData
    ? [
        ...(next.dailyRevenues
          ? next.dailyRevenues.map((i: any) => +i.revenue)
          : []),
        ...(previous.dailyRevenues
          ? previous.dailyRevenues.map((i: any) => +i.revenue)
          : []),
      ]
    : [];

  const max = all.length ? Math.max(...all) : 1000000;
  const ceilMax = getCeilNum(max);
  const base = 20;
  const maxHeight = 200;
  const xLinesCount = 9;

  let lines = [];
  for (let i = 1; i <= xLinesCount; i++) {
    const bottom = i * base + base;
    const value = getFloorNum(ceilMax / 9) * i;
    lines.push({ bottom, value });
  }

  useEffect(() => {
    function handleResize() {
      //chartOne.current.clientWidth >= 17 :this logic determines, if we will display dates, when showing in divs
      setChartOneVisibleMonths(
        !!(chartOne && chartOne.current) && chartOne.current.clientWidth >= 17
      );
      setChartTwoVisibleMonths(
        !!(chartTwo && chartTwo.current) && chartTwo.current.clientWidth >= 17
      );
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [next, previous]);

  return (
    <div
      className={`${styles['chart-wrapper']} ${
        !chartOneVisibleMonths &&
        !chartTwoVisibleMonths &&
        styles['hidden-months']
      }`}
    >
      <div className={styles['chart-base']}>
        <hr style={{ bottom: '20px' }} />
        <hr style={{ bottom: '40px' }} />
        <hr style={{ bottom: '60px' }} />
        <hr style={{ bottom: '80px' }} />
        <hr style={{ bottom: '100px' }} />
        <hr style={{ bottom: '120px' }} />
        <hr style={{ bottom: '140px' }} />
        <hr style={{ bottom: '160px' }} />
        <hr style={{ bottom: '180px' }} />

        <div className={styles['y-axis']}>
          {lines.map((i) => (
            <span key={i.value} style={{ bottom: `${i.bottom}px` }}>
              {kFormatter(i.value)}
            </span>
          ))}
        </div>

        {previous && previous.dailyRevenues && (
          <div
            className={`${styles['chart-one']} ${
              !chartOneVisibleMonths && styles['hidden-months']
            }`}
          >
            {previous.dailyRevenues.map((dailyRevenue: any, index: number) => {
              const height =
                +dailyRevenue.revenue === 0
                  ? maxHeight
                  : (+dailyRevenue.revenue * maxHeight) / ceilMax;
              return (
                <Tooltip
                  key={index}
                  title={
                    <span
                      style={{
                        display: 'inline-block',
                        textAlign: 'center',
                        whiteSpace: 'pre-line',
                      }}
                    >{`${formatDate(dailyRevenue.date)}\n${formatMoney(
                      dailyRevenue.revenue,
                      dailyRevenue.currency,
                      false
                    )}`}</span>
                  }
                >
                  <div
                    ref={index === 0 ? chartOne : null}
                    style={{ height: `${height >= 0 ? height : 0}px` }}
                    className={`${+dailyRevenue.revenue === 0 && styles.zero}`}
                  >
                    <span className={styles.month}>
                      {`${fd(dailyRevenue.date, 'MM')}`}
                      <br />
                      {`${fd(dailyRevenue.date, 'YYYY')}`}
                    </span>
                  </div>
                </Tooltip>
              );
            })}

            <div className={styles['x-axis']}>
              {t('chart-historical-monthly-revenue')}
            </div>
          </div>
        )}

        <div
          className={`${styles['chart-two']} ${
            !chartTwoVisibleMonths && styles['hidden-months']
          }`}
        >
          {next &&
            next.dailyRevenues &&
            next.dailyRevenues.map((dailyRevenue: any, index: number) => {
              const height =
                +dailyRevenue.revenue === 0
                  ? maxHeight
                  : (+dailyRevenue.revenue * maxHeight) / ceilMax;
              return (
                <Tooltip
                  key={index}
                  title={
                    <span
                      style={{
                        display: 'inline-block',
                        textAlign: 'center',
                        whiteSpace: 'pre-line',
                      }}
                    >{`${formatDate(dailyRevenue.date)}\n${formatMoney(
                      dailyRevenue.revenue,
                      dailyRevenue.currency,
                      false
                    )}`}</span>
                  }
                >
                  <div
                    ref={index === 0 ? chartTwo : null}
                    style={{ height: `${height >= 0 ? height : 0}px` }}
                    className={`${+dailyRevenue.revenue === 0 && styles.zero}`}
                  >
                    <span className={styles.month}>
                      {`${fd(dailyRevenue.date, 'MM')}`}
                      <br />
                      {`${fd(dailyRevenue.date, 'YYYY')}`}
                    </span>
                  </div>
                </Tooltip>
              );
            })}

          {next && next.dailyRevenues && (
            <div className={styles['x-axis']}>
              {t('chart-assumption-sales')}
            </div>
          )}
        </div>

        {!hasData && (
          <div className={styles['no-data']}>{t('chart-no-data')}</div>
        )}
      </div>
    </div>
  );
};

export default Chart;
