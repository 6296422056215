import React, { useRef, useImperativeHandle } from 'react';
import styles from './Input.module.scss';
import { IInputRef } from '../../types/global';

interface IInputProps {
  id: string;
  label: string;
  value?: string;
  type: string;
  pattern?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isValid?: boolean;
}

const Input = React.forwardRef<IInputRef, IInputProps>((props, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const activate = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const getValue = () => {
    return inputRef.current ? inputRef.current.value : '';
  };

  const setValue = (value: string) => {
    if (inputRef.current) {
      inputRef.current.value = value;
    }
  };

  useImperativeHandle(ref, () => ({
    focus: activate,
    getValue,
    setValue,
  }));

  return (
    <div
      className={`${styles['input-wrapper']} ${
        props.isValid === false ? styles.invalid : ''
      }`}
    >
      <label htmlFor={props.id}>{props.label}</label>
      <span className={styles.input}>
        <input
          ref={inputRef}
          type={props.type}
          id={props.id}
          value={props.value}
          pattern={props.pattern}
          placeholder={props.placeholder}
          required={props.required}
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
      </span>
    </div>
  );
});

export default Input;
