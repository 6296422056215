import { FC } from 'react';
import { formatMoney } from '../../../utils/format';
import { IsoCurrencyCode, LeadProductDto } from '../../../types/api';
import styles from './Application.module.scss';
import { useTranslation } from 'react-i18next';

interface IApplicationSummaryProps {
  product: LeadProductDto;
}

const ApplicationSummary: FC<IApplicationSummaryProps> = ({ product }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['summary-wrapper']}>
      <span>{t('application-summary')}</span>
      <div className={styles['summary-info-wrapper']}>
        <div className={styles['summary-info']}>
          <div className={styles.financing}>
            <span>{t('application-financing-amount')}</span>
            <span className={styles.value}>
              {formatMoney(
                product.amount,
                product.currency as IsoCurrencyCode,
                false
              )}
            </span>
          </div>

          <span className={styles.title}>{t('application-monthly-fee')}</span>
          <span className={styles.value}>
            {formatMoney(
              product.feeMonthly,
              product.currency as IsoCurrencyCode,
              false
            )}
          </span>
          <span className={styles.title}>
            {t('application-prolongation-fee')}
          </span>
          <span className={styles.value}>
            {formatMoney(
              product.feeProlongation,
              product.currency as IsoCurrencyCode,
              false
            )}
          </span>

          <div className={styles.total}>
            <span>{t('application-complete-amount')}</span>
            <span className={styles.value}>
              {formatMoney(
                product.amountComplete,
                product.currency as IsoCurrencyCode,
                false
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationSummary;
