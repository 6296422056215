import { useState, FC, MouseEvent } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';

//@ts-ignore
import { ReactComponent as UserCircle } from '../../assets/user-circle.svg';
import styles from './Header.module.scss';

import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import CloseIcon from '@mui/icons-material/Close';
import ListItemIcon from '@mui/material/ListItemIcon';
import WorkIcon from '@mui/icons-material/Work';
import Logout from '@mui/icons-material/Logout';

import telIcon from '../../assets/icons/tel-icon-white.svg';
import emailIcon from '../../assets/icons/email-icon-white.svg';
import questionIcon from '../../assets/icons/question-icon-white.svg';

import { formatDate, formatMoney } from '../../utils/format';
import {
  ExternalUserDto,
  PartnerConnectionDto,
  UserType,
} from '../../types/api';
import { useRole, useSplitLocation } from '../../hooks';
import { useAppDispatch, useAppSelector } from '../../selectors/selectors';
import { setCurrentCustomerAction } from '../../store/slices/currentCustomerSlice';

interface IHeaderProps {
  activeInfo?: Record<string, any>;
  applicationData?: null | Record<string, any>;
  currentUser: ExternalUserDto;
  selectCustomerHandler?: (
    customerId: string,
    selectedPartner: PartnerConnectionDto | null
  ) => void;
  getPartnerOverview?: (customerId: string) => void;
  showMyFinancing?: boolean;
  customerId: string;
  isRegistration?: boolean;
  fetchCustomerRelatedData?: (
    customerId: string
  ) => Promise<{ resApplication: any; resLead: any }>;
}

const Header: FC<IHeaderProps> = ({
  applicationData,
  currentUser,
  getPartnerOverview,
  selectCustomerHandler,
  showMyFinancing,
  customerId,
  activeInfo,
  isRegistration,
  fetchCustomerRelatedData,
}) => {
  const { t } = useTranslation();

  const [open, setState] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isAuthenticated, logout } = useAuth0();
  const role = useRole();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const splitLocation = useSplitLocation();

  const isBuckaroo =
    splitLocation.includes('offer') && splitLocation.includes('Buckaroo');

  const { currentCustomer } = useAppSelector((state) => state.currentCustomer);

  const openAcc = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const goto = (target: string) => {
    navigate(target);
  };

  const menuItems =
    role === UserType.PARTNER
      ? [
          { slug: 'overview', title: t('header-menu-overview') },
          { slug: 'financing', title: t('header-menu-financing') },
          { slug: 'commissions', title: t('header-menu-commissions') },
          { slug: 'settings', title: t('header-menu-settings') },
        ]
      : [
          { slug: 'overview', title: t('header-menu-overview') },
          ...(showMyFinancing
            ? [{ slug: 'my-financing', title: t('header-menu-my-financing') }]
            : []),
          // { slug: 'insights', title: t('header-menu-insights') },
          { slug: 'connections', title: t('header-menu-connections') },
          { slug: 'settings', title: t('header-menu-settings') },
        ];

  const menuItemsNav = menuItems.map((item, index) => {
    const url =
      role === UserType.PARTNER
        ? `/${item.slug}`
        : `/${customerId}/${item.slug}`;
    return (
      <li
        key={index}
        className={`${styles['l' + (index + 1)]} ${
          splitLocation.includes(item.slug) && styles.active
        }`}
        onClick={() =>
          fetchCustomerRelatedData && fetchCustomerRelatedData(customerId)
        }
      >
        <Link to={url}>
          <svg viewBox="0 0 56 60" preserveAspectRatio="none">
            <use xlinkHref="#nav"></use>
          </svg>
          {index !== 0 && (
            <svg viewBox="0 0 56 60" preserveAspectRatio="none">
              <use xlinkHref="#nav2"></use>
            </svg>
          )}
          <svg viewBox="0 0 56 60" preserveAspectRatio="none">
            <use xlinkHref="#nav"></use>
          </svg>
          <span>{item.title}</span>
        </Link>
      </li>
    );
  });

  const toggleDrawer = (open: boolean) => {
    setState(open);
  };

  const selectCustomer = (customerId: string) => {
    const currentCustomer = currentUser?.customers?.find(
      (c) => c.id === customerId
    );
    dispatch(setCurrentCustomerAction(currentCustomer!));

    const partners = currentCustomer?.partners ?? [];
    selectCustomerHandler &&
      selectCustomerHandler(
        customerId,
        partners && partners.length ? partners[0] : null
      );
    navigate(`/${customerId}/overview`);
  };

  const headerInfo = activeInfo ? (
    role === UserType.PARTNER ? (
      <>
        <p>
          <span>{t('header-info-active-financing')}</span>
        </p>
        <p>
          {activeInfo.activeCount} {t('header-info-active-financing-count')}
        </p>
        <p>
          {t('header-info-principal-sum')}{' '}
          <span>{formatMoney(activeInfo.activePrincipalSum)}</span>
        </p>
      </>
    ) : (
      <>
        <p>
          {t('header-info-next-installment')}{' '}
          <span>{formatDate(activeInfo.dueDate)}</span>
        </p>
        <p>
          {t('installment-amount-header')}{' '}
          <span>{formatMoney(activeInfo.totalPaid, activeInfo.currency)}</span>
        </p>
      </>
    )
  ) : (
    ''
  );

  return (
    <header
      className={`${
        !activeInfo && !applicationData && styles['no-header-info']
      }`}
    >
      {headerInfo && (
        <section className={styles['header-info']}>
          <svg viewBox="0 0 43 60" preserveAspectRatio="none">
            <use xlinkHref="#header-info"></use>
          </svg>

          {headerInfo}

          <svg viewBox="0 0 43 60" preserveAspectRatio="none">
            <use xlinkHref="#header-info"></use>
          </svg>
        </section>
      )}

      {applicationData && (
        <div className={styles['application']}>
          <p>
            {t('header-info-financing-calculation')}{' '}
            <span>
              {formatMoney(
                applicationData.product.amount,
                applicationData.product.currency
              )}
            </span>
          </p>
        </div>
      )}

      {!isRegistration && (
        <nav>
          <ul>{menuItemsNav}</ul>
        </nav>
      )}

      {!isRegistration && (
        <>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="Menu"
            className={styles.menu}
            onClick={() => toggleDrawer(true)}
            sx={{
              ml: 2,
              color: '#ffffff',
              position: 'absolute',
              left: 0,
              bottom: 0,
              padding: '10px',
            }}
          >
            <MenuIcon sx={{ fontSize: '2.2rem' }} />
          </IconButton>
          <Drawer
            anchor="left"
            open={open}
            onClose={() => toggleDrawer(false)}
            PaperProps={{ sx: { width: '100%' } }}
            sx={{ top: '20px' }}
          >
            <div
              className={styles['hamburger-menu']}
              onClick={() => toggleDrawer(false)}
              onKeyDown={() => toggleDrawer(false)}
            >
              <section className={styles['header-info']}>{headerInfo}</section>

              <section className={styles.navigation}>
                <div className={styles['nav-header']}>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="Menu"
                    className={styles.menu}
                    onClick={() => toggleDrawer(false)}
                    sx={{
                      ml: 2,
                      color: '#ffffff',
                      padding: '10px',
                    }}
                  >
                    <CloseIcon sx={{ fontSize: '2.2rem' }} />
                  </IconButton>

                  {isAuthenticated && (
                    <IconButton
                      size="large"
                      edge="start"
                      color="inherit"
                      aria-label="Menu"
                      className={styles.menu}
                      sx={{
                        mr: 2,
                        color: '#ffffff',
                        padding: '10px',
                      }}
                      onClick={() => {
                        if (isRegistration) {
                          localStorage.removeItem('lead_id');
                        }

                        logout({
                          returnTo: window.location.origin,
                        });
                      }}
                    >
                      <LoginIcon sx={{ fontSize: '2.2rem' }} />
                    </IconButton>
                  )}
                </div>

                <ul>
                  {menuItems.map((item, index) => {
                    const url =
                      role === UserType.PARTNER
                        ? `/${item.slug}`
                        : `/${customerId}/${item.slug}`;

                    return (
                      <li key={index}>
                        <Link to={url}>
                          <span>{item.title}</span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>

                <div className={styles.contacts}>
                  <ul>
                    <li>
                      <h3>{t('header-support')}</h3>
                    </li>
                    <li>
                      <img
                        src={questionIcon}
                        title={t('support-url-text')}
                        alt={t('support-url-text')}
                        className={styles['large-img']}
                      />
                      <a
                        href={
                          isBuckaroo
                            ? t('support-url-buckaroo')
                            : t('support-url')
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t('support-url-text')}
                      </a>
                    </li>
                    <li>
                      <img
                        src={telIcon}
                        title={t('support-phone-number')}
                        alt={t('support-phone-number')}
                      />
                      <a href={`tel:${t('support-phone-number')}`}>
                        {t('support-phone-number')}
                      </a>
                    </li>
                    <li>
                      <img
                        src={emailIcon}
                        title={t('support-email')}
                        alt={t('support-email')}
                      />
                      <a href={`mailto:${t('support-email')}`}>
                        {t('support-email')}
                      </a>
                    </li>
                  </ul>
                </div>
              </section>
            </div>
          </Drawer>{' '}
        </>
      )}

      {/* <a href='/#' className={styles.menu}>
        <img src={menu} title='Menu' alt='Menu' />
      </a> */}

      {/* {isAuthenticated && (
        <IconButton
          size='large'
          edge='start'
          color='inherit'
          aria-label='Menu'
          className={styles.logout}
          sx={{
            mr: 2,
            color: '#ffffff',
            position: 'absolute',
            right: 0,
            top: '10px',
            padding: '10px',
            flexDirection: 'column',
          }}
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
        >
          <LoginIcon sx={{ fontSize: '2.2rem' }} />
          <span className={styles['logout-text']}>{t('header-logout')}</span>
        </IconButton>
      )} */}

      {isAuthenticated && (
        <div className={styles.user}>
          <Box
            sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
          >
            <Tooltip title={currentUser?.name ?? ''}>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{
                  color: '#ffffff',
                }}
                aria-controls={openAcc ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openAcc ? 'true' : undefined}
                className={styles.logout}
              >
                <UserCircle className={styles['user-circle']} />
              </IconButton>
            </Tooltip>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={openAcc}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {!isRegistration && (
              <MenuItem onClick={() => goto(`/${customerId}/settings`)}>
                <Avatar /> {currentUser?.name ?? ''}
              </MenuItem>
            )}
            {currentUser?.customers && !isRegistration && <Divider />}
            {!isRegistration &&
              currentUser?.customers &&
              currentUser?.customers.map((c) => {
                return (
                  <MenuItem
                    key={c.id}
                    // onClick={() => goto(`?customerId=${c.id}`)}
                    onClick={() => selectCustomer(c.id ?? '')}
                    className={`${
                      c.id === customerId ? styles.activeCustomer : ''
                    }`}
                  >
                    <ListItemIcon>
                      <WorkIcon fontSize="small" />
                    </ListItemIcon>
                    {c.name}
                  </MenuItem>
                );
              })}
            {!isRegistration && currentUser?.partners && <Divider />}
            {!isRegistration &&
              currentUser?.partners &&
              currentUser.partners.map((p) => {
                return (
                  <MenuItem
                    key={p.id}
                    onClick={() =>
                      getPartnerOverview && getPartnerOverview(p.id)
                    }
                    className={`${
                      p.id === customerId ? styles.activeCustomer : ''
                    }`}
                  >
                    <ListItemIcon>
                      <WorkIcon fontSize="small" />
                    </ListItemIcon>
                    {p.name}
                  </MenuItem>
                );
              })}
            {!isRegistration && <Divider />}
            <MenuItem
              onClick={() =>
                logout({
                  returnTo: window.location.origin,
                })
              }
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              {t('header-logout')}
            </MenuItem>
          </Menu>
          {role === UserType.CUSTOMER && !isRegistration && (
            <span className={styles.name}>
              {currentCustomer?.name || currentCustomer?.regNum}
            </span>
          )}
          {role === UserType.PARTNER && (
            <span className={styles.name}>{currentUser?.name ?? ''}</span>
          )}
        </div>
      )}

      {/* <a href='/#' className={styles.logout}>
        <img src={logout} title='Logout' alt='Logout' />
      </a> */}
    </header>
  );
};

export default Header;
