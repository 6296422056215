export enum RegistrationSteps {
  Business,
  Websites,
  ICO,
  ICOData,
  NewExecutive,
  Phone,
  Pin,
  FinalStep,
}

export const registrationSteps: Array<RegistrationSteps> = [
  RegistrationSteps.Business,
  RegistrationSteps.Websites,
  RegistrationSteps.ICO,
  RegistrationSteps.ICOData,
  RegistrationSteps.NewExecutive,
  RegistrationSteps.Phone,
  RegistrationSteps.Pin,
  RegistrationSteps.FinalStep,
];

export const registrationStepNames: Record<string, string> = {
  1: 'business',
  2: 'websites',
  3: 'ico',
  4: 'representatives',
  5: 'phone-or-new-representative',
  6: 'pin',
  7: 'final-step',
};
