import TextField, { TextFieldProps } from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { COLORS } from '../../constants/colors';
import { formatCurrency } from '../../utils/format';
import { IsoCurrencyCode } from '../../types/api';

interface TextFieldStyledProps {
  noline?: boolean;
  pattern?: string;
  currency?: IsoCurrencyCode;
}

const TextFieldStyled = styled(
  ({
    currency,
    noline,
    pattern,
    ...props
  }: TextFieldStyledProps & TextFieldProps) => (
    <TextField
      {...props}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        disableUnderline: Boolean(noline),
        endAdornment: currency ? (
          <InputAdornment position="end">
            {formatCurrency(currency)}
          </InputAdornment>
        ) : null,
        ...props.InputProps,
      }}
      inputProps={{
        pattern: pattern,
        ...props.inputProps,
      }}
    />
  )
)(({ theme }) => ({
  '& .MuiInputAdornment-positionEnd': {
    minWidth: 'auto !important',
  },

  '.MuiInputLabel-root, .MuiInput-root input, textarea': {
    color: COLORS.text,
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 'initial',
    fontFamily: '"Inter", sans-serif',
  },
  textarea: {
    border: '1px solid #838383',
    borderRadius: '8px',
    padding: '1rem',
  },
}));

interface TextEditProps {
  id?: string;
  label?: string;
  value?: string;
  onChange?: (value: string) => void;
  autoFocus?: boolean;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  noline?: boolean;
  type?: string;
  required?: boolean;
  disabled?: boolean;
  pattern?: string;
  fullWidth?: boolean;
  name?: string;
  variant?: TextFieldProps['variant'];
  size?: TextFieldProps['size'];
  currency?: IsoCurrencyCode;
}

const TextEdit: FC<TextEditProps> = ({
  id,
  label,
  value,
  onChange,
  autoFocus,
  placeholder,
  multiline,
  rows,
  noline,
  type,
  required,
  disabled,
  pattern,
  fullWidth,
  variant,
  size,
  currency,
}) => (
  <TextFieldStyled
    autoFocus={Boolean(autoFocus)}
    margin="dense"
    id={id}
    label={label}
    type={type || 'text'}
    required={required}
    disabled={disabled}
    fullWidth={fullWidth}
    multiline={Boolean(multiline)}
    rows={rows || 1}
    size={size}
    variant={variant ?? 'standard'}
    value={value}
    placeholder={placeholder}
    pattern={pattern}
    noline={noline}
    currency={currency}
    onChange={(event) => {
      onChange && onChange(event.target.value);
    }}
  />
);

export default TextEdit;
