import MuiToggleButton from '@mui/material/ToggleButton';
import { styled } from '@mui/material/styles';
import { COLORS } from '../../constants/colors';

interface IToggleButtonProps {
  indicativeOffer?: boolean;
}

export const ToggleButton = styled(MuiToggleButton)<IToggleButtonProps>(
  ({ indicativeOffer }) => ({
    '&.MuiToggleButton-root': {
      width: '4.563rem',
      height: '2.625rem',
      borderRadius: '9px',
      border: '1px solid #C6CBD0',
      fontWeight: 700,
      fontSize: '1.125rem',
      '@media (max-width: 450px)': {
        width: indicativeOffer ? '3.563rem' : '4.563rem',
        height: indicativeOffer ? '2.125rem' : '2.625rem',
        fontSize: indicativeOffer ? '15px' : '1.125rem',
      },
    },
    '&.Mui-selected, &.Mui-selected:hover': {
      color: 'white',
      backgroundColor: COLORS.primary,
    },
  })
);
