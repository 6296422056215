import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { systemLanguages } from '../../constants/system';

const getBrowserLanguage = () => {
  const language = navigator.language.split('-')[0]; // if en => en, else 'en-US' -> 'en'
  return systemLanguages.includes(language) ? language : 'en'; //Default to english, if browser langage is different than languages we support
};

const languageSlice = createSlice({
  name: 'language',
  initialState: getBrowserLanguage(),
  reducers: {
    setAppLanguage: (state, action: PayloadAction<string>) => {
      return action.payload ? action.payload : state;
    },
  },
});

export const { setAppLanguage } = languageSlice.actions;
export default languageSlice.reducer;
