import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { RobotImage } from '../UI/RobotImage';

interface HotGlueDialogWrapperProps {
  open: boolean;
  onClose: () => void;
  title: ReactNode;
  content: ReactNode;
}

const HotGlueDialog: React.FC<HotGlueDialogWrapperProps> = ({
  open,
  onClose,
  title,
  content,
}) => {
  const closeDialog = (
    event: React.SyntheticEvent<Element, Event>,
    reason?: string
  ) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth>
      <Box display="flex" gap={1} p={2}>
        <RobotImage style={{ width: '200px', height: '200px' }} />
        <Box display="flex" flexDirection="column">
          <DialogTitle>
            <Typography
              sx={{
                background:
                  'linear-gradient(268.66deg, #a26bf4 -12%, #00dec4 118.95%)',
                borderRadius: '16px 16px 16px 0px',
                padding: '0.8rem 1.6rem',
                color: 'white',
                fontWeight: 'bolder',
              }}
            >
              {title}
            </Typography>
          </DialogTitle>
          <DialogContent>{content}</DialogContent>
          <DialogActions>
            <Button onClick={closeDialog} variant="contained" size="large">
              {t('application-button-continue')}
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default HotGlueDialog;
