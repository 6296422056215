import { useTranslation } from 'react-i18next';

import Card from '../UI/Card';

import styles from './Error.module.scss';
import { RobotImage } from '../UI/RobotImage';

const Error = () => {
  const { t } = useTranslation();

  return (
    <div className={styles['error-wrapper']}>
      <RobotImage />
      <div className={styles.info}>
        <h1>{t('error-title')}</h1>
        <Card>{t('error-info')}</Card>
      </div>
    </div>
  );
};

export default Error;
