import { useState, useEffect, SyntheticEvent } from 'react';
import { useLocation } from 'react-router-dom';

import { useLoading } from './context/loading-context';
import { useErrorHandler } from './context/error-handler-context';
import axios from './utils/axios';
import { b64toBlob } from './utils/file';

import Snackbar from '@mui/material/Snackbar';
import Loading from './components/UI/Loading';
import { Alert } from '@mui/material';
import { parseErrorMessage } from './utils/utils';

function Terms() {
  const location = useLocation();
  const { pathname } = location;

  let splitLocation = pathname.split('/');
  splitLocation = splitLocation.filter(Boolean);

  const language = splitLocation[1]?.toUpperCase() ?? 'CZ';

  //Parsing terms url
  let apiUrl = `terms/${language}/current`;
  if (splitLocation.length === 4 && splitLocation[2] === 'revision') {
    apiUrl = `terms/${language}/revision/${splitLocation[3]}`;
  } else if (splitLocation.length === 3) {
    apiUrl = `terms/${language}/revision/${splitLocation[2]}`;
  }

  const [pdfSrc, setPdfSrc] = useState<string | null>(null);
  const { loading, setLoading } = useLoading();
  const { error, setError } = useErrorHandler();

  const handleCloseErrorAlert = (
    event: SyntheticEvent<Element, Event> | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setError(null);
  };

  useEffect(() => {
    setLoading(true);
    //@ts-ignore to ignore type issue
    axios.defaults.skipAuthToken = true;
    axios
      .get(apiUrl)
      .then((res) => {
        setLoading(false);
        const contentType = 'application/pdf';
        const blob = b64toBlob(res.data, contentType);
        const blobUrl = URL.createObjectURL(blob);
        setPdfSrc(blobUrl);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [apiUrl, setError, setLoading]);

  return (
    <div style={{ position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }}>
      <Loading isLoading={loading} />
      <Snackbar
        open={Boolean(error)}
        autoHideDuration={5000}
        onClose={handleCloseErrorAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseErrorAlert}
          severity="error"
          sx={{ width: '100%' }}
        >
          {parseErrorMessage(error)}
        </Alert>
      </Snackbar>

      {pdfSrc && <iframe src={pdfSrc} title="VOP" width="100%" height="100%" />}
    </div>
  );
}

export default Terms;
