import { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';

import NewRep2Form from '../../Sections/NewRep2Form';
import Button from '../../UI/Button';
import styles from './Application.module.scss';
import {
  ApplicationSubmitRep2Dto,
  ApplicationSubmitRep2DtoCustom,
  ExternalUserDto,
  RepresentativeDto,
} from '../../../types/api';
import { capitalizeFirstLetter } from '../../../utils/format';

interface IApplicationRep2Props {
  applicationId: string;
  customerId: string;
  rep1Id: string;
  currentUser: ExternalUserDto;
  handleRep2Submit: (
    data: ApplicationSubmitRep2Dto | ApplicationSubmitRep2DtoCustom,
    representativeId: string
  ) => void;
  handleRep2NoSubmit: () => void;
}

const ApplicationRep2: FC<IApplicationRep2Props> = ({
  handleRep2Submit,
  handleRep2NoSubmit,
  applicationId,
  customerId,
  currentUser,
}) => {
  const { t } = useTranslation();
  const [isRep2, setIsRep2] = useState<boolean>(false);

  const [companyRepresentatives, setCompanyRepresentatives] = useState<
    Array<RepresentativeDto>
  >([]);

  const onYesClick = () => {
    setIsRep2(true);
  };

  const rep2body = isRep2 ? (
    <div>
      <div className="gradient info">
        <h1>
          <span>{t('application-rep2-title')}</span>
        </h1>
      </div>

      <div className={styles['body']}>
        <p>{t('application-rep2-txt')}</p>
        {companyRepresentatives.length > 0 && (
          <p>{t('application-rep2-txt3')}</p>
        )}

        <NewRep2Form
          applicationId={applicationId}
          customerId={customerId}
          currentUser={currentUser}
          submitHandler={handleRep2Submit}
          companyRepresentatives={companyRepresentatives}
          setCompanyRepresentatives={setCompanyRepresentatives}
        />
      </div>
    </div>
  ) : (
    <div>
      <div className="gradient info">
        <h1>
          <span>{t('application-rep2-title2')}</span>
        </h1>
      </div>

      <div className={styles['body']}>
        <p>{t('application-rep2-txt2')}</p>

        <div className={styles.buttons}>
          <Button
            className="application"
            onClick={onYesClick}
            id="app_rep2_yes"
          >
            {capitalizeFirstLetter(t('common-yes'))}
          </Button>
          <Button
            className="application"
            onClick={handleRep2NoSubmit}
            id="app_rep2_no"
          >
            {capitalizeFirstLetter(t('common-no'))}
          </Button>
        </div>
      </div>
    </div>
  );

  return rep2body;
};

export default ApplicationRep2;
