import i18n from 'i18next';
import { IsoCurrencyCode } from '../types/api';
import dayjs, { Dayjs } from 'dayjs';

export function formatDate(date?: Date | string): string {
  if (!date) return '';

  const parsedDate = dayjs(date);

  if (!parsedDate.isValid()) return '';

  return parsedDate.locale(i18n.language).format('L'); // 'L' uses the locale's default format
}

export const toLocaleDate = (date?: Dayjs | string | Date) => {
  if (!date) return '';

  const parsedDate = dayjs(date);

  if (!parsedDate.isValid()) return '';

  return parsedDate.format('YYYY-MM-DD');
};

export function formatMoney(
  value: unknown,
  currency = IsoCurrencyCode.CZK,
  displayDecimal: boolean = true
): string {
  const locale =
    {
      cs: 'cs-CZ',
      en: 'en-US',
      nl: 'nl-NL',
    }[i18n.language] || 'cs-CZ';

  const val = value ? +value : 0;

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits:
      currency === IsoCurrencyCode.EUR && displayDecimal ? 2 : 0,
    maximumFractionDigits:
      currency === IsoCurrencyCode.EUR && displayDecimal ? 2 : 0,
  });

  return formatter.format(val);
}

export function formatCurrency(currency: IsoCurrencyCode) {
  const locales =
    {
      cs: 'cs-CZ',
      en: 'en-US',
      nl: 'nl-NL',
    }[i18n.language] || 'cs-CZ';

  const formatted = new Intl.NumberFormat(locales, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(0);

  return formatted.replace(/\d/g, '').trim();
}

export const capitalizeFirstLetter = (input: unknown) => {
  if (typeof input !== 'string' || input.length === 0) {
    return '';
  }
  return input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();
};

export function kFormatter(num: number) {
  return Math.abs(num) > 999
    ? (Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1) + 'k'
    : Math.sign(num) * Math.abs(num);
}

export function formatDayString(days: number) {
  let str = i18n.t('common-days-alt');
  if (days === 1) {
    str = i18n.t('common-day');
  } else if (days > 1 && days < 5) {
    str = i18n.t('common-days');
  }
  return `${days} ${str}`;
}
