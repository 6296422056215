import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OfferDto } from '../../types/api';
import { RootState } from '../../store';

interface ICOfferState {
  offer: OfferDto | null;
}

const initialState: ICOfferState = {
  offer: null,
};

const offerSlice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    setCurrentCustomerOffer: (state, action: PayloadAction<OfferDto>) => {
      state.offer = action.payload;
    },
  },
});

export const { setCurrentCustomerOffer } = offerSlice.actions;
export const selectCurrentCustomerOffer = (state: RootState) =>
  state.offer.offer;

export default offerSlice.reducer;
