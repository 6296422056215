import React, { ChangeEvent, FC, useState } from 'react';
import AddressForm, { IUBOPerson } from '../../Common/AddressForm';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

interface IUBOPersonFormProps {
  formData: IUBOPerson;
  setFormData: React.Dispatch<React.SetStateAction<IUBOPerson>>;
  pep: boolean;
  setPep: React.Dispatch<React.SetStateAction<boolean>>;
  formErrors: IUBOPerson;
}

const UBOPersonForm: FC<IUBOPersonFormProps> = ({
  formData,
  pep,
  setFormData,
  setPep,
  formErrors,
}) => {
  const handlePepChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPep(event.target.value === 'pep');
  };

  const { t } = useTranslation();

  return (
    <>
      <AddressForm
        formData={formData}
        errors={formErrors}
        setFormData={setFormData}
      />

      <FormControl sx={{ my: 2 }}>
        <FormLabel id="demo-row-radio-buttons-group-label">
          <Typography>{t('application-ubo-pep')}</Typography>
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="pep-radio-group"
          value={pep ? 'pep' : 'nopep'}
          onChange={handlePepChange}
        >
          <FormControlLabel
            value="pep"
            control={<Radio />}
            label={t('common-yes')}
          />
          <FormControlLabel
            value="nopep"
            control={<Radio />}
            label={t('common-no')}
          />
        </RadioGroup>
      </FormControl>

      <Typography>{t('application-ubo-declaration')}</Typography>
      <Typography>{t('application-ubo-declaration2')}</Typography>
    </>
  );
};

export default UBOPersonForm;
