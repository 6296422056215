import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export const FirstStepGreeting = () => {
  const { t } = useTranslation();
  return (
    <Box sx={{ margin: '1rem', ml: 0 }}>
      <Typography variant="h4" sx={{ fontSize: { xs: '1rem' } }}>
        {t('registration.registration-welcome-message')}
      </Typography>
      <Typography
        variant="h4"
        fontWeight="bold"
        sx={{ fontSize: { xs: '1rem' } }}
      >
        {t('registration.registration-invitation')}
      </Typography>
    </Box>
  );
};
