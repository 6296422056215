import { CSSProperties, FC } from 'react';
import robotLarge from '../../assets/robot-large.svg';

interface IRobotImageProps {
  style?: CSSProperties;
}

export const RobotImage: FC<IRobotImageProps> = ({ style }) => {
  return (
    <img
      src={robotLarge}
      title="Robot"
      alt="Robot"
      style={style}
    />
  );
};
