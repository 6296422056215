import { FC, FormEvent } from 'react';
import { useTranslation, Trans } from 'react-i18next';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import Dialog from '../UI/Dialog';
import Button from '../UI/Button';

import styles from './ConnectionDialog.module.scss';
import { useLoading } from '../../context/loading-context';
import Loading from '../UI/Loading';
import { COLORS } from '../../constants/colors';

interface IConnectionDialogProps {
  onClose: (value: boolean) => void;
  open: boolean;
  selectedPartner: any;
}

const ShopifyDialogOld: FC<IConnectionDialogProps> = ({
  onClose,
  open,
  selectedPartner,
}) => {
  const { t } = useTranslation();

  const { loading, setLoading } = useLoading();

  const handleClose = (success = false) => {
    onClose(success);
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const redirectUrl =
      'https://apps.shopify.com/62892e3e9106e320d03a836974a63d45/preview/en';

    setLoading(true);

    await new Promise((resolve) => setTimeout(resolve, 2000));

    window.location.replace(redirectUrl);
  };

  return (
    <Dialog
      open={open}
      title={t('connections-dialog-title-alt')}
      handleClose={handleClose}
    >
      <Loading isLoading={loading} />
      <div className={styles['dialog-wrapper']}>
        <form onSubmit={submitHandler}>
          <DialogContent
            sx={{
              p: '0.5rem 5rem 0.5rem 5rem !important',
              color: COLORS.text,
              fontWeight: 700,
              fontSize: '1.2rem',
              lineHeight: 'initial',
              fontFamily: '"Inter", sans-serif',
            }}
          >
            <DialogContentText>
              <Trans
                i18nKey="connections-dialog-info-alt"
                components={[selectedPartner.name]}
              ></Trans>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{ p: '0rem 5rem 2rem 5rem' }}>
            <Button type="submit" className="gradient dialog">
              <span>{t('connections-dialog-form-send')}</span>
            </Button>
          </DialogActions>
        </form>
      </div>
    </Dialog>
  );
};

export default ShopifyDialogOld;
