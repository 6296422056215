import { useAuth0 } from '@auth0/auth0-react';
import { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { ExternalUserDto, UserType } from '../types/api';
import { APIService } from '../services/api';
import { useAppDispatch } from '../selectors/selectors';
import { setCurrentUserAction } from '../store/slices/currentUserSlice';

const audience = process.env.REACT_APP_API_AUDIENCE;

export const useQuery = (): URLSearchParams => {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useRole = (): UserType => {
  const { user } = useAuth0();

  const role = user?.[`${audience}/roles`].includes('Partners')
    ? UserType.PARTNER
    : UserType.CUSTOMER;

  return role;
};

export const useScreenSize = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.up('xs'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return {
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
  };
};

export function useCurrentUser() {
  const [currentUser, setCurrentUser] = useState<ExternalUserDto | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  const { isAuthenticated } = useAuth0();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const loadUser = async () => {
      if (!isAuthenticated) return;

      try {
        const userData = await APIService.getCurrentUser();
        setCurrentUser(userData);
        dispatch(setCurrentUserAction(userData));
        setLoading(false);
      } catch (error) {
        setError(error as Error);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    loadUser();
  }, [isAuthenticated]);

  return { currentUser, error, isLoading };
}

export const useSplitLocation = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/').filter(Boolean);

  return splitLocation;
};
