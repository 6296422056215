import { isJsonString } from './string';

export function getUtmFromStorage() {
  const localUtm = localStorage.getItem('utm_params');

  if (localUtm && isJsonString(localUtm)) {
    const lastDate = Date.now() / 1000 - 30 * 24 * 60 * 60;
    const utmArr = JSON.parse(localUtm);

    let utm = null;
    for (let i = 0; i < utmArr.length; i++) {
      const element = utmArr[i];
      if (element.date && new Date(element.date).getTime() / 1000 > lastDate) {
        utm = element;
        break;
      }
    }

    return utm;
  }

  return null;
}
