import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import styles from './ApplicationProgress.module.scss';
import { Application } from '../../../types/api';

interface IExtendedApplication extends Application {
  partnerCode?: string;
  merchantId?: string;
}

interface IPartner {
  code: string;
  merchantId: string;
}

interface IApplicationProgressProps {
  className?: string;
  application: IExtendedApplication;
  partner?: IPartner;
  customerId: string;
}

const ApplicationProgress: FC<IApplicationProgressProps> = ({
  application,
  className,
  customerId,
}) => {
  const { t } = useTranslation();

  let c = '';
  if (className) {
    className.split(' ').forEach((e) => {
      c += styles[e] + ' ';
    });
  }

  const calcUrl = `/${customerId}/offer/${application.partnerCode}/${application.merchantId}`;

  return (
    <div className={`${styles['progress-wrapper']} ${c}`}>
      <div className={styles.info}>
        {
          // @ts-ignore
          application.inputState === 'INTEREST' && (
            <span>
              <Link to={calcUrl}>{t('application-progress-calculation')}</Link>
            </span>
          )
        }
        {
          // @ts-ignore
          application.inputState !== 'INTEREST' && (
            <span
              className={`${
                ['OFFER'].includes(application.inputState) ? styles.active : ''
              }`}
            >
              {t('application-progress-calculation')}
            </span>
          )
        }
        <span className={styles['line-separator']}></span>
        <span
          className={`${
            ['INTEREST', 'REP2', 'PEP', 'REVENUE', 'BANK'].includes(
              application.inputState
            )
              ? styles.active
              : ''
          }`}
        >
          {t('application-progress-identification')}
        </span>
        <span className={styles['line-separator']}></span>
        <span
          className={`${
            ['PSD', 'KYC'].includes(application.inputState) ? styles.active : ''
          }`}
        >
          {t('application-progress-verification')}
        </span>
        <span className={styles['line-separator']}></span>
        <span
          className={`${
            ['DONE'].includes(application.inputState) &&
            [
              'SIGNED',
              'CONTRACT_SENT',
              'CHECKING',
              'SENT_FOR_APPROVING',
              'WAIT_NEW_CONDITIONS',
            ].includes(application.state)
              ? styles.active
              : ''
          }`}
        >
          {t('application-progress-approval')}
        </span>
        <span className={styles['line-separator']}></span>
        <span
          className={`${
            ['DONE'].includes(application.inputState) &&
            ![
              'SIGNED',
              'CONTRACT_SENT',
              'CHECKING',
              'SENT_FOR_APPROVING',
              'WAIT_NEW_CONDITIONS',
            ].includes(application.state)
              ? styles.active
              : ''
          }`}
        >
          {t('application-progress-contract')}
        </span>
      </div>
    </div>
  );
};

export default ApplicationProgress;
