import { PartnerPartnerCategory } from '../../../types/api';
import { RegistrationSteps } from '../../../constants/registrationSteps';
import { CustomerRegistrationCountries } from '../../../enums/customerRegistrationCountries';

export const phoneRegex = /^\+[1-9]{1}[0-9]{3,14}$/;

export type TUserRegisterData = {
  ico: string;
  phone: string;
  country: CustomerRegistrationCountries | '';
  websites: string[];
  business: PartnerPartnerCategory | string;
  currentStep: RegistrationSteps;
  stepHistory: number[];
  pinDigits: string[];
  newExecutive: {
    firstName: string;
    lastName: string;
    dob: string;
    address: {
      city: string;
      country: {
        code: string;
        name: string;
        phone: string;
      };
      line1: string;
      zip: string;
    };
  };
};

export const getInitialData = (): TUserRegisterData => {
  return {
    ico: '',
    country: '',
    phone: '',
    websites: [''],
    business: PartnerPartnerCategory.GASTRO,
    currentStep: RegistrationSteps.Business,
    stepHistory: [],
    pinDigits: ['', '', '', '', '', ''],
    newExecutive: {
      firstName: '',
      lastName: '',
      dob: '',
      address: {
        city: '',
        country: {
          code: '',
          name: '',
          phone: '',
        },
        line1: '',
        zip: '',
      },
    },
  };
};
