import { ReactNode, FC } from 'react';
import { SliderThumb, SliderProps } from '@mui/material/Slider';

interface ICustomThumbProps {
  children: ReactNode;
  other: SliderProps;
}

const CustomThumb: FC<ICustomThumbProps> = ({ children, ...other }) => {
  return (
    <SliderThumb {...other}>
      {children}
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
      <span className="airbnb-bar" />
    </SliderThumb>
  );
};

export default CustomThumb;
