import { useTranslation, Trans } from 'react-i18next';
import { FC } from 'react';
import Button from '../../UI/Button';
import styles from './Application.module.scss';

interface IApplicationPepProps {
  handlePepSubmit: (value: boolean) => void;
}

const ApplicationPep: FC<IApplicationPepProps> = ({ handlePepSubmit }) => {
  const { t } = useTranslation();

  const onClick = (value: boolean) => {
    handlePepSubmit(value);
  };

  return (
    <div>
      <div className="gradient info">
        <h1>
          <span>{t('application-pep-title')}</span>
        </h1>
      </div>

      <div className={styles['body']}>
        <Trans
          i18nKey="application-pep-txt"
          components={{
            par: <p></p>,
            list: <ul></ul>,
            listLine: <li></li>,
          }}
        />

        <div className={styles.buttons}>
          <Button
            className="application"
            onClick={() => onClick(true)}
            id="app_pep_yes"
          >
            {t('application-pep-yes')}
          </Button>
          <Button
            className="application"
            onClick={() => onClick(false)}
            id="app_pep_no"
          >
            {t('application-pep-no')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApplicationPep;
