import { AddressType, ExternalUserDto } from '../types/api';
import axios from '../utils/axios';

export class APIService {
  static async verifyPhone(
    phone: string,
    leadId: string,
    currentUser: ExternalUserDto | null
  ) {
    try {
      const dataToSubmit = {
        partyId: currentUser?.partyId,
        type: AddressType.PHONE,
        address: phone,
        leadId,
      };

      const res = await axios.post(
        'parties/address-verifications',
        dataToSubmit
      );
      localStorage.setItem('verification_data', JSON.stringify(res.data));
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  static async getCurrentUser() {
    try {
      const res = await axios.get<ExternalUserDto>('users/current');
      return res.data;
    } catch (e) {
      throw new Error('There was a problem fetching user information.');
    }
  }
}
