import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';

interface SnsError {
  code: string;
  error: string;
}

export type TCustomError = string | null | SnsError;

interface IErrorHandlerContextType {
  error: TCustomError;
  setError: (err: TCustomError) => void;
}

const ErrorHandlerContext = createContext<IErrorHandlerContextType | null>(
  null
);

interface ErrorHandlerProviderProps {
  children: ReactNode;
}

export function ErrorHandlerProvider({ children }: ErrorHandlerProviderProps) {
  const { t } = useTranslation();
  const [error, setError] = useState<TCustomError>(null);

  const handleError = useCallback(
    (err: TCustomError) => {
      if (!err) {
        setError(null);
        return;
      }

      if (typeof err === 'string') {
        setError(err);
        return;
      }

      setError(t('error-info'));
    },
    [t]
  );

  const value: IErrorHandlerContextType = { error, setError: handleError };

  return (
    <ErrorHandlerContext.Provider value={value}>
      {children}
    </ErrorHandlerContext.Provider>
  );
}

export function useErrorHandler() {
  const context = useContext(ErrorHandlerContext);

  if (!context) {
    throw new Error('useErrorHandler must be used within ErrorHandlerProvider');
  }
  return context;
}
