import useMediaQuery from '@mui/material/useMediaQuery';
import { ReactNode, FC, CSSProperties } from 'react';
import { default as DialogMui, DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { styled, useTheme } from '@mui/material/styles';
import styles from './Dialog.module.scss';
import { COLORS } from '../../constants/colors';

interface ExtendedDialogProps extends DialogProps {
  customStyles?: CSSProperties;
}

const DialogStyled = styled((props: ExtendedDialogProps) => (
  <DialogMui sx={{ zIndex: (theme) => theme.zIndex.drawer }} {...props} />
))((props) => () => ({
  '.MuiPaper-root': {
    borderRadius: '20px',
    ...props.customStyles,
  },
  '@media (max-width: 600px)': {
    '.MuiPaper-root': {
      borderRadius: '0',
      width: '100%',
    },
  },
}));

interface IDialogTitleStyledProps {
  children: ReactNode;
  onClose: () => void;
  id: string;
}

const DialogTitleStyled: FC<IDialogTitleStyledProps> = ({
  children,
  onClose,
}) => {
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: '2rem 5rem 0rem 5rem',
        color: COLORS.text,
        fontWeight: 700,
        fontSize: '1.6rem',
        lineHeight: 'initial',
        fontFamily: '"Inter", sans-serif',
      }}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            left: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

interface IDialogProps {
  open: boolean;
  handleClose: (isClose: boolean) => void;
  title?: string;
  customStyles?: CSSProperties;
  children: ReactNode;
}

const Dialog: FC<IDialogProps> = ({
  handleClose,
  open,
  customStyles,
  title,
  children,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <DialogStyled
      keepMounted
      open={open}
      fullScreen={fullScreen}
      className={styles.dialog}
      customStyles={customStyles || {}}
    >
      <DialogTitleStyled
        id="customized-dialog-title"
        onClose={() => handleClose(false)}
      >
        {title}
      </DialogTitleStyled>
      {children}
    </DialogStyled>
  );
};

export default Dialog;
