import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { generateRandomNonce } from '../../utils/utils';

const screen_hint = 'signin';

const Login = () => {
  const { loginWithRedirect } = useAuth0();
  const { idp } = useParams<{ idp?: string }>();

  const nonce = generateRandomNonce();
  const state = JSON.stringify({ nonce });

  useEffect(() => {
    localStorage.setItem('nonce', nonce);
  }, [nonce]);

  useEffect(() => {
    if (idp) {
      loginWithRedirect({
        connection: idp,
        screen_hint,
        state,
      });
    } else {
      loginWithRedirect({
        screen_hint,
        state,
      });
    }
  }, [idp]);

  return null;
};

export default Login;
