import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { formatDate, formatMoney } from '../../utils/format';
import styles from './HistoryTable.module.scss';
import {
  LoanLoanState,
  LoansOverviewDtoPreviousLoanDto,
} from '../../types/api';
import { useAppSelector } from '../../selectors/selectors';

interface IHistoryTableProps {
  history: Array<LoansOverviewDtoPreviousLoanDto>;
  customerId: string;
}

const HistoryTable: FC<IHistoryTableProps> = ({ customerId, history }) => {
  const { t } = useTranslation();

  const { defaultCurrency } = useAppSelector((state) => state.currentCustomer);

  return (
    <div className={styles['history-table']}>
      {history.map((i) => {
        return (
          <div
            className={`${styles.line} ${
              i.state === LoanLoanState.PAID && styles.done
            }`}
            key={i.id}
          >
            <span className={styles.value}>
              {formatMoney(i.amount, defaultCurrency!)}
            </span>
            <span className={styles['line-separator']}></span>
            <span className={styles.date}>
              {`${formatDate(i.from)} - ${formatDate(i.to)}`}
            </span>
            <span className={styles['end-date']}>{formatDate(i.to)}</span>
            <span className={styles['line-separator']}></span>
            <span
              className={`${styles.status} ${
                i.state !== 'PAID' && styles['in-progress']
              }`}
            >
              {i.state === 'PAID'
                ? t('financing-history-paid')
                : t('financing-history-in-progress')}
            </span>
            <span className={styles.detail}>
              <Link to={`/${customerId}/my-financing/${i.id}`}>{`${t(
                'common-details'
              )} >`}</Link>
            </span>
          </div>
        );
      })}
    </div>
  );
};

export default HistoryTable;
