import { useState, useEffect, FC } from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '../UI/Dialog';

import axios from '../../utils/axios';
import { getDateDiff, formatDate } from '../../utils/date';
import { useLoading } from '../../context/loading-context';
import { useErrorHandler } from '../../context/error-handler-context';

import styles from './ConnectionPSD2Dialog.module.scss';
import { RobotImage } from '../UI/RobotImage';
import { BankDto, IsoCurrencyCode, PsdConnectionDto } from '../../types/api';
import { useAppSelector } from '../../selectors/selectors';
import IbanSubmission from '../Common/IbanSubmission';

interface IConnectionPSD2DialogProps {
  onClose: (value: boolean) => void;
  open: boolean;
  customerId: string;
}

const ConnectionPSD2Dialog: FC<IConnectionPSD2DialogProps> = ({
  customerId,
  onClose,
  open,
}) => {
  const { t } = useTranslation();

  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  const [newConnection, setNewConnection] = useState(false);
  const [connections, setConnections] =
    useState<Array<PsdConnectionDto> | null>(null);
  const [banks, setBanks] = useState<Array<BankDto>>([]);

  const {
    currentCustomer: { currentCustomer, defaultCurrency },
    application: { application },
  } = useAppSelector((state) => state);

  const currency = (application?.product?.currency ||
    defaultCurrency) as IsoCurrencyCode;

  const handleClose = (success = false) => {
    resetDialog();
    onClose(success);
  };

  const resetDialog = () => {};

  const handleBankSubmit = (data: { bankId?: string; iban?: string }) => {
    setLoading(true);
    axios
      .post(`customers/${customerId}/psd-connections`, data)
      .then((res) => {
        window.open(res.data.url, '_self');
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    Promise.all([
      axios.get(`customers/${customerId}/psd-connections`),
      axios.get(`banks/${currentCustomer?.country ?? 'CZ'}`),
    ])
      .then((res) => {
        const connections = res[0].data
          ? res[0].data.filter((b: any) => b.bankId)
          : [];

        setConnections(connections);
        setBanks(res[1].data.filter((b: any) => b.active && b.psdSupported));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [customerId]);

  return (
    <Dialog
      open={open}
      title={t('connections-psd2-bank-connection')}
      handleClose={handleClose}
    >
      <div className={styles['dialog-wrapper']}>
        {newConnection || (connections && connections.length === 0) ? (
          <>
            <RobotImage />
            <IbanSubmission
              banks={banks}
              isPsdConnection
              title={t('connections-psd2-acc-number')}
              currency={currency}
              handleBankSubmit={handleBankSubmit}
              setError={setError}
            />
          </>
        ) : null}

        {!newConnection && connections && connections.length > 0 && (
          <div className={styles['connection-wrapper']}>
            {connections.map((c) => {
              const bank = banks.find((b) => b.id === c.bankId);
              const diffValidTo = c.consentValidTo
                ? getDateDiff(c.consentValidTo, Date.now())
                : null;

              return (
                <div key={c.connectionRef} className={styles.connection}>
                  <div className={styles.header}>
                    <h2>{bank?.name}</h2>
                    {c.iban && <span>{c.iban}</span>}
                    {c.consentValidTo && (
                      <span className={styles['valid-to']}>
                        {t('connections-psd2-valid-to')}:{' '}
                        {formatDate(c.consentValidTo)}
                      </span>
                    )}
                  </div>

                  {diffValidTo && diffValidTo >= 3 && (
                    <button
                      tabIndex={0}
                      type="button"
                      onClick={() => handleBankSubmit({ bankId: c.bankId })}
                    >
                      <svg
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="AddIcon"
                      >
                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"></path>
                      </svg>
                    </button>
                  )}
                </div>
              );
            })}

            <button type="button" onClick={() => setNewConnection(true)}>
              {t('connections-psd2-new-connection')}
            </button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default ConnectionPSD2Dialog;
