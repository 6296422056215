import { useState, useEffect, useCallback, FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useLoading } from '../../../context/loading-context';
import { useErrorHandler } from '../../../context/error-handler-context';
import axios from '../../../utils/axios';

import Button from '../../UI/Button';

import styles from './Application.module.scss';
import { Application } from '../../../types/api';

interface IApplicationPsdProps {
  applicationId: string;
  handlePsd: (data: { application: Application }) => void;
}

const ApplicationPsd: FC<IApplicationPsdProps> = ({
  applicationId,
  handlePsd,
}) => {
  const { t } = useTranslation();

  const { step, state } = useParams();
  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  const [redirect, setRedirect] = useState(false);

  const onClick = useCallback(() => {
    setLoading(true);
    axios
      .post(`applications/${applicationId}/step/psd/start`, {})
      .then((res) => {
        window.open(res.data, '_self');
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [applicationId, setError, setLoading]);

  const callIsComplete = useCallback(() => {
    setLoading(true);
    axios
      .post(`applications/${applicationId}/step/psd/complete`, {})
      .then((res) => {
        switch (res.data.psdState) {
          case 'PENDING':
            setTimeout(() => {
              setLoading(false);
              callIsComplete();
            }, 15 * 1000);
            break;

          case 'FAILED':
            setLoading(false);
            onClick();
            break;

          case 'ACTIVE':
            setLoading(false);
            handlePsd(res.data);
            break;

          default:
            break;
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [applicationId, handlePsd, onClick, setError, setLoading]);

  useEffect(() => {
    if (step === 'psd' && state === 'completed' && !redirect) {
      setRedirect(true);
      callIsComplete();
    }
  }, [callIsComplete, redirect, state, step]);

  return (
    <div>
      <div className="gradient info">
        <h1>
          <span>{t('application-psd-title')}</span>
        </h1>
      </div>

      <div className={styles['body']}>
        <p>{t('application-psd-txt')}</p>
        <p>{t('application-psd-txt2')}</p>

        <div className={styles.buttons}>
          <Button className="application" onClick={onClick} id="app_psd2_start">
            {t('application-button-continue')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ApplicationPsd;
