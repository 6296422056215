import React, { FC } from 'react';
import { RegisterInfo } from '../../../../types/api';
import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { formatAddress } from '../../../../utils/utils';
import { registrationStepNames } from '../../../../constants/registrationSteps';

interface IInfoOrganizationProps {
  infoOrganization: RegisterInfo | null;
  onSubmit: () => void;
}

export const InfoOrganization: FC<IInfoOrganizationProps> = ({
  infoOrganization,
  onSubmit,
}) => {
  const { t } = useTranslation();

  if (!infoOrganization) {
    return null;
  }

  return (
    <Stack mt={2}>
      <Typography variant="body1">
        {t('registration.validation.company-found')}
      </Typography>
      <br />
      <Typography fontWeight="bold">
        {t('registration-form-regnum')}:
      </Typography>
      <Typography>{infoOrganization.organization?.regNum}</Typography>

      <br />
      <Typography fontWeight="bold">
        {t('registration-form-company')}:
      </Typography>
      <Typography>{infoOrganization.organization?.name}</Typography>

      <br />
      <Typography fontWeight="bold">{t('business-address')}:</Typography>
      <Typography>
        {formatAddress(infoOrganization.organization?.address)}
      </Typography>
      <br />
      <Button
        color="primary"
        variant="contained"
        type="submit"
        size="large"
        sx={{ maxWidth: '200px' }}
        disableFocusRipple
        disableRipple
        disableTouchRipple
        id={`step-${registrationStepNames[3]}`}
        onClick={onSubmit}
      >
        {t('confirm-continue')}
      </Button>
    </Stack>
  );
};
