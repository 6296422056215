import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import flagCs from '../assets/flags/flag-cs.svg';
import flagEn from '../assets/flags/flag-en.svg';
import flagnl from '../assets/flags/flag-nl.svg';
import { systemLanguages } from '../constants/system';

i18n.use(initReactI18next).init({
  fallbackLng: systemLanguages, // Fallback to English, Dutch or Czech
  resources: {
    cs: {
      translations: require('./locales/cs/translations.json'),
    },
    en: {
      translations: require('./locales/en/translations.json'),
    },
    nl: {
      translations: require('./locales/nl/translations.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
  interpolation: {
    escapeValue: false, // React already escapes output by default
  },
});

//@ts-ignore we need to update the library, to fix the TS errors
i18n.fpLangs = [
  {
    code: 'cs',
    flag: flagCs,
    label: 'Český jazyk',
  },
  {
    code: 'en',
    flag: flagEn,
    label: 'English language',
  },
  {
    code: 'nl',
    flag: flagnl,
    label: 'Nederlandse taal',
  },
];

export default i18n;
