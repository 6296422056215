// TODO: import only used functions
import dayjs from 'dayjs';

type StringOrNumberOrDate = string | number | Date;

export function getDatesInRange(startDate: Date, endDate: Date) {
  const date = new Date(startDate.getTime());

  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export function formatDate(date?: StringOrNumberOrDate, format = 'YYYY-MM-DD') {
  if (!date) return '';
  return dayjs(date).format(format);
}

export function getDateDiff(
  date1: StringOrNumberOrDate,
  date2: StringOrNumberOrDate,
  unitOfTime?: 'days'
) {
  const d1 = dayjs(date1);
  const d2 = dayjs(date2);

  return d2.diff(d1, unitOfTime);
}

export const calculateRemainingPinValidationTime = (
  targetTimestamp: string
): number => {
  const targetMoment = dayjs(targetTimestamp);
  const currentMoment = dayjs();
  const duration = dayjs.duration(targetMoment.diff(currentMoment));

  return Math.floor(duration.asSeconds());
};

export const displayRemainingTime = (seconds: number): null | string => {
  if (!seconds || seconds <= 0) {
    return null;
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};
