import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Snackbar {
  id: number;
  message: string;
  variant: 'success' | 'error' | 'warning' | 'info';
}

interface SnackbarsState {
  snackbars: Snackbar[];
}

const initialState: SnackbarsState = {
  snackbars: [],
};

const snackbarsSlice = createSlice({
  name: 'snackbars',
  initialState,
  reducers: {
    addSnackbar: {
      reducer: (state, action: PayloadAction<Omit<Snackbar, 'id'>>) => {
        const id = Date.now(); // Generate a unique ID
        state.snackbars.push({ id, ...action.payload });
      },
      prepare: (snackbar: Omit<Snackbar, 'id'>) => {
        return { payload: snackbar };
      },
    },
    removeSnackbar: (state, action: PayloadAction<{ id: number }>) => {
      state.snackbars = state.snackbars.filter(
        (snackbar) => snackbar.id !== action.payload.id
      );
    },
  },
});

export const { addSnackbar, removeSnackbar } = snackbarsSlice.actions;

export default snackbarsSlice.reducer;
