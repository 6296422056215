import { FC, MouseEvent } from 'react';
import {
  Stack,
  ToggleButtonGroup,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { ToggleButton } from '../../UI/ToggleButton';
import { IsoCurrencyCode } from '../../../types/api';
//import ToggleSwitch from '../../UI/ToggleSwitch';

interface ICurrencySwitcherProps {
  currency: IsoCurrencyCode;
  handleCurrencyChange: (
    event: MouseEvent<HTMLElement>,
    newCurrency: IsoCurrencyCode
  ) => void;
  isIndicativeOffer?: boolean;
}

const CurrencySwitcher: FC<ICurrencySwitcherProps> = ({
  currency,
  handleCurrencyChange,
  isIndicativeOffer,
}) => {
  const { t } = useTranslation();
  const isMobileScreen = useMediaQuery('(max-width:450px)');

  return (
    <Stack
      direction="row"
      spacing={3}
      justifyContent="space-between"
      alignItems="center"
      sx={{ marginBottom: isIndicativeOffer ? '0.25rem;' : '2rem' }}
    >
      <Typography
        variant="h6"
        fontWeight="bold"
        sx={{
          fontSize: isMobileScreen && isIndicativeOffer ? '15px' : '1.125rem',
        }}
      >
        {t('offer-currency-header')}
      </Typography>
      <ToggleButtonGroup
        color="primary"
        value={currency}
        exclusive
        onChange={handleCurrencyChange}
        aria-label="text alignment"
        sx={{ borderRadius: '9px' }}
      >
        <ToggleButton
          value={IsoCurrencyCode.CZK}
          disabled={currency === IsoCurrencyCode.CZK}
          aria-label="czk currency"
          indicativeOffer={isIndicativeOffer}
        >
          {i18n.language === 'en' ? 'CZK' : 'Kč'}
        </ToggleButton>
        <ToggleButton
          value={IsoCurrencyCode.EUR}
          disabled={currency === IsoCurrencyCode.EUR}
          aria-label="euro currency"
          indicativeOffer={isIndicativeOffer}
        >
          €
        </ToggleButton>
      </ToggleButtonGroup>
      {/* <ToggleSwitch /> */}
    </Stack>
  );
};

export default CurrencySwitcher;
