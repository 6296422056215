import { useTranslation } from 'react-i18next';

import styles from './Filter.module.scss';
import { FC } from 'react';
import {
  ExternalUserDto,
  ExternalUserDtoConnectedPartnerDto,
  ExternalUserDtoCustomerDto,
} from '../../types/api';
import { Chip, Stack } from '@mui/material';

interface IPartnerImageProps {
  name: string;
}

const PartnerImage: FC<IPartnerImageProps> = ({ name }) => {
  try {
    const image = require(`../../assets/partners/${name}.png`);
    if (!image) return null;

    return <img src={image} title={name} alt={name} />;
  } catch (error) {
    return null;
  }
};

interface IFilterProps {
  customerId: string;
  currentUser: ExternalUserDto;
  partner: ExternalUserDtoConnectedPartnerDto;
  changePartner?: (item: ExternalUserDtoConnectedPartnerDto) => void;
}

const Filter: FC<IFilterProps> = ({
  changePartner,
  currentUser,
  partner,
  customerId,
}) => {
  const { t } = useTranslation();

  const partners = currentUser?.customers?.find(
    (c: ExternalUserDtoCustomerDto) => c?.id === customerId
  )?.partners;

  const handlePartnerChange = (code: string): void => {
    const selectedPartner = partners?.find(
      (p: ExternalUserDtoConnectedPartnerDto) => p.code === code
    );

    if (changePartner && selectedPartner) {
      changePartner(selectedPartner);
    }
  };

  return (
    <div className={styles['filter-wrapper']}>
      <div className={styles.partners}>
        <label>{t('overview-filter-partner')}</label>
        <Stack direction="row" ml={1} flexWrap="wrap" rowGap={1}>
          {partners?.map(
            (p, index: number) => (
              <Chip
                key={p.code || index}
                label={p.name}
                color={p.code === partner.code ? 'primary' : 'default'}
                onClick={() => handlePartnerChange(p.code!)}
                size="small"
                sx={{ mr: 1 }}
              />
            )
          )}
        </Stack>
      </div>
      <div className={styles.client}>
        {partner && <PartnerImage name={partner.code ?? ''} />}
      </div>
    </div>
  );
};

export default Filter;
