import { useState, useEffect, useCallback, FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { useLoading } from '../../../context/loading-context';
import { useErrorHandler } from '../../../context/error-handler-context';
import axios from '../../../utils/axios';
import { formatMoney, formatDate } from '../../../utils/format';
import { b64toBlob } from '../../../utils/file';

import pdf from '../../../assets/pdf.svg';
import Table from '../../Sections/Table';
import { default as Commissions } from '../../Sections/Partner/PartnerCommissions';
import PdfDialog from '../../Dialogs/PdfDialog';

import styles from './PartnerCommissions.module.scss';
import { PartnerLoansOverviewDto } from '../../../types/api';

interface IPartnerCommissionsProps {
  customerId: string;
  partnerOverview: PartnerLoansOverviewDto;
}

const PartnerCommissions: FC<IPartnerCommissionsProps> = ({
  customerId,
  partnerOverview,
}) => {
  const { t } = useTranslation();
  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  const [tableData, setTableData] = useState<Array<any>>([]);
  const [open, setOpen] = useState(false);
  const [pdfSrc, setPdfSrc] = useState('');

  const loadData = useCallback(() => {
    setLoading(true);
    axios
      .get(`loans/partners/${customerId}/commissions/statements`)
      .then((res) => {
        console.log(res.data);

        setLoading(false);
        setTableData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  }, [setError, setLoading, customerId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const columns = [
    {
      name: 'period',
      title: t('partner-commissions-table-period'),
      align: 'center',
    },
    {
      name: 'amount',
      title: t('partner-commissions-table-amount'),
      align: 'right',
    },
    {
      name: 'fileUrl',
      title: t('partner-commissions-table-overview'),
      align: 'center',
    },
    {
      name: 'createdAt',
      title: t('partner-commissions-table-created-at'),
      align: 'center',
    },
  ];

  // const handleDownload = (e, url) => {
  //   e.preventDefault();

  //   setLoading(true);
  //   axios
  //     .post('outputs/documents/authorize', { url })
  //     .then((res) => {
  //       console.log(res.data);

  //       setLoading(false);
  //       if (res.data && res.data.url) {
  //         window.open(res.data.url, '_blank');
  //       }
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       setError(err);
  //     });
  // };

  const openFile = (e: MouseEvent<HTMLAnchorElement>, id: string) => {
    e.preventDefault();

    setLoading(true);
    axios
      .get(`commissions/statements/${id}`)
      .then((res) => {
        setLoading(false);
        const contentType = 'application/pdf';
        const blob = b64toBlob(res.data, contentType);
        const blobUrl = URL.createObjectURL(blob);
        setPdfSrc(blobUrl);
        setOpen(true);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const rows = tableData
    ? tableData.map((t) => {
        return {
          id: t.id,
          period: t.period,
          amount: formatMoney(t.amount),
          fileUrl: (
            <a
              className={styles.pdf}
              href="/#"
              // onClick={(e) => handleDownload(e, t.filename)}
              onClick={(e) => openFile(e, t.id)}
              // href={t.fileUrl}
              // rel='noreferrer'
              // target='_blank'
            >
              <img width="30px" src={pdf} alt="pdf" />
            </a>
          ),
          createdAt: formatDate(t.createdAt),
        };
      })
    : [];

  return (
    <>
      <div className={styles['commissions-wrapper']}>
        <Commissions partnerOverview={partnerOverview} />
      </div>

      <div className={styles['content-wrapper']}>
        <div className={styles.table}>
          <Table
            header={t('partner-commissions-title')}
            columns={columns}
            rows={rows}
          />
        </div>
      </div>

      {open && (
        <PdfDialog
          open={open}
          onClose={() => setOpen(false)}
          // title={t('installment-contract')}
          pdfSrc={pdfSrc}
          title=""
        />
      )}
    </>
  );
};

export default PartnerCommissions;
