import { useTranslation } from 'react-i18next';

import { formatDate, formatMoney, formatDayString } from '../../utils/format';
import { getDatesInRange } from '../../utils/date';
import styles from './NextPayment.module.scss';
import {
  InstallmentInstallmentState,
  IsoCurrencyCode,
  LoanDtoInstallmentDto,
} from '../../types/api';
import { FC } from 'react';

interface INextPaymentProps {
  planned: LoanDtoInstallmentDto;
  currency?: IsoCurrencyCode;
}

const NextPayment: FC<INextPaymentProps> = ({
  planned: { dueDate = '', total = 0, paidTotal = 0, state },
  currency,
}) => {
  const { t } = useTranslation();

  let overdueDays = 0;
  if (state === InstallmentInstallmentState.OVERDUE) {
    const today = new Date();
    const d2 = new Date(dueDate);
    const days = getDatesInRange(d2, today);
    overdueDays = days.length - 1;
  }

  const isPartly = paidTotal > 0;

  return (
    <>
      <div
        className={`${styles['next-payment']} ${
          state === 'OVERDUE' && styles.overdue
        } ${isPartly && styles.partly}`}
      >
        <span className={styles.title}>{t('installment-next')}</span>
        <span className={styles['line-separator']}></span>
        <span className={styles.date}>{formatDate(dueDate)}</span>
        <span className={styles['line-separator']}></span>
        <span className={styles.value}>{formatMoney(total, currency)}</span>
      </div>

      {isPartly && (
        <div className={`${styles['partly-paid']}`}>
          <span>{t('installment-partial')}</span>
          <span className={styles['line-separator']}></span>
          <span className={styles.value}>
            {formatMoney(paidTotal, currency)}
          </span>
        </div>
      )}

      {state === 'OVERDUE' && (
        <div className={styles['overdue-info']}>
          <span className={styles.info}>
            {t('installment-overdue-days')} {formatDayString(overdueDays)}
          </span>

          {overdueDays >= 1 && overdueDays <= 4 && (
            <span>{t('installment-overdue-days-info')}</span>
          )}

          {overdueDays >= 5 && (
            <span>
              {t('installment-overdue-days-info-alt2')}{' '}
              <a
                href="https://form.typeform.com/to/LU0k6LLd#status=NEW"
                target="_blank"
                rel="noreferrer"
              >
                {t('installment-overdue-days-info-alt3')}
              </a>{' '}
              {t('installment-overdue-days-info-alt4')}
            </span>
          )}
        </div>
      )}
    </>
  );
};

export default NextPayment;
