import { useTranslation } from 'react-i18next';
import { FC } from 'react'; 
import Tooltip from '@mui/material/Tooltip';
import styles from './PieChart.module.scss';

interface IPieChartProps {
  value?: number;
  type?: string
  width?: string | number;
  border?: string | number
}

const PieChart: FC<IPieChartProps> = ({type, border, value = 0, width}) => {
  const { t } = useTranslation();

  const parsedValue = Math.round(+value);
 
  if (type && type === 'table') {
    width = '20px';
    border = '4px';
  }

  return (
    <Tooltip followCursor title={`${parsedValue}%`}>
      <div className={`${styles['pie-wrapper']} ${styles[type ?? '']}`}>
        <div
          className={`${styles.pie} ${styles.animate} ${styles['no-round']}`}
          style={{
            //@ts-ignore
            '--p': value,
            '--c': '#00dec4',
            '--w': width,
            '--b': border,
          }}
        >
          {type && <div></div>}
          {!type && (
            <div>
              {t('chart-paid')}
              <span>{value}%</span>
            </div>
          )}
        </div>

        {type === 'table' && <span>{value}%</span>}
      </div>
    </Tooltip>
  );
};

export default PieChart;
