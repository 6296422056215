import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActiveApplicationDto } from '../../types/api';
import { RootState } from '../../store';

interface ICurrentCustomerState {
  application: ActiveApplicationDto | null;
}

const initialState: ICurrentCustomerState = {
  application: null,
};

const activeApplicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplicationAction: (state, action: PayloadAction<ActiveApplicationDto>) => {
      state.application = action.payload;
    },
  },
});

export const { setApplicationAction } = activeApplicationSlice.actions;
export const selectApplication = (state: RootState) => state.application;

export default activeApplicationSlice.reducer;
