import { useState, useEffect, useRef, FC } from 'react';
import { useTranslation } from 'react-i18next';

import Tooltip from '@mui/material/Tooltip';

import { formatMoney } from '../../../utils/format';
import { getCeilNum } from '../../../utils/number';
import { formatDate as fd } from '../../../utils/date';

import styles from './PartnerCommissions.module.scss';
import { PartnerLoansOverviewDto } from '../../../types/api';

interface IPartnerCommissionsProps {
  partnerOverview: PartnerLoansOverviewDto;
}

const PartnerCommissions: FC<IPartnerCommissionsProps> = ({
  partnerOverview,
}) => {
  const { t } = useTranslation();
  const chart = useRef<HTMLDivElement | null>(null);
  const [hasScroll, setHasScroll] = useState(false);

  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth();

  const isSameMonth = (date: Date | string) => {
    const d = new Date(date);
    return d.getFullYear() === year && d.getMonth() === month;
  };

  const chartTwoAmount = [
    ...(partnerOverview.monthlyPaidCommissions?.map((i) => +i.amount) ?? []),
    ...(partnerOverview.monthlyExpectedCommissions?.map((i) => +i.amount) ??
      []),
  ].filter(Number);

  const chartTwomax = Math.max(...chartTwoAmount);
  const chartTwoMaxHeight = 250;
  const chartTwoCeilMax = getCeilNum(chartTwomax);

  const comm = [];
  for (let i = 0; i < 12; i++) {
    const commissions = partnerOverview?.monthlyPaidCommissions?.find((c) =>
      isSameMonth(c.date)
    );
    if (commissions) comm.unshift(commissions);
    else {
      comm.unshift({
        date: `${year}-${('0' + (month + 1)).slice(-2)}-01`,
        amount: 0,
        paid: true,
      });
    }

    if (month > 0) {
      month--;
    } else {
      month = 11;
      year--;
    }
  }

  const chartTwoData = [
    ...comm.map((i) => {
      return {
        date: i.date,
        amount: +i.amount || 0,
        paid: true,
      };
    }),
    ...(partnerOverview?.monthlyExpectedCommissions?.map((i) => {
      return {
        date: i.date,
        amount: +i.amount || 0,
        paid: false,
      };
    }) ?? []),
  ];
  console.log(chartTwoData);

  useEffect(() => {
    function handleResize() {
      if (chart.current) {
        setHasScroll(chart.current.scrollWidth > chart.current.clientWidth);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <section className={`${styles.section} ${styles['no-border']}`}>
      <div className={`${styles['info-box']} ${styles['no-border']}`}>
        <div className={styles.info}>
          <span>
            <span className={styles.bold}>
              {t('partner-commissions-expected')}{' '}
            </span>
            {t('partner-commissions-commission')}
          </span>
          <span className={`${styles.rounded} ${styles.alt5}`}>
            <span>{formatMoney(partnerOverview.expectedCommission)}</span>
          </span>
        </div>

        <div className={styles.info}>
          <span>
            <span className={styles.bold}>
              {t('partner-commissions-paid')}{' '}
            </span>
            {t('partner-commissions-total')}
          </span>
          <span className={`${styles.rounded} ${styles.alt3}`}>
            {formatMoney(partnerOverview.totalCommission)}
          </span>
        </div>

        <div className={styles.info}>
          <span>
            <span className={styles.bold}>
              {t('partner-commissions-month-average')}
            </span>
          </span>
          <span className={`${styles.rounded} ${styles.alt}`}>
            {formatMoney(partnerOverview.commissionMonthlyAvg)}
          </span>
        </div>
      </div>

      <div
        className={`${styles['chart-wrapper']} ${
          hasScroll ? styles['has-scroll'] : ''
        }`}
      >
        <div className={styles['y-axis']}>
          <span>{t('partner-overview-how-much')}</span>
        </div>

        <div ref={chart} className={styles.chart}>
          {chartTwoData.map((i, key) => {
            const height =
              i.amount === 0
                ? chartTwoMaxHeight
                : (i.amount * chartTwoMaxHeight) / chartTwoCeilMax;

            return (
              <div className={styles['line-wrapper']} key={key}>
                <div className={styles.lines}>
                  <Tooltip
                    title={
                      <span
                        style={{
                          display: 'inline-block',
                          textAlign: 'center',
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {formatMoney(i.amount)}
                      </span>
                    }
                  >
                    <div
                      className={styles.line}
                      style={{ height: `${chartTwoMaxHeight}px` }}
                    >
                      <div
                        className={`${i.amount === 0 && styles.zero} ${
                          styles[i.paid ? 'paid' : 'expected']
                        }`}
                        style={{ height: `${height >= 0 ? height : 0}px` }}
                      ></div>
                    </div>
                  </Tooltip>
                </div>
                {fd(i.date, 'MM/YYYY')}
              </div>
            );
          })}
        </div>

        <div className={styles['x-axis']}>
          <span className={styles.highlight}>
            {t('partner-commissions-paid-alt')}{' '}
          </span>
          {t('partner-commissions-expected-alt')}
        </div>
      </div>
    </section>
  );
};

export default PartnerCommissions;
