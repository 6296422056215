import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';

export const StyledPin = styled(TextField)`
  width: 2.8125rem;
  height: 3.4375rem;

  input {
    text-align: center;
  }

  @media (max-width: 450px) {
    width: 2rem;
    height: 2.5rem;
    input {
      padding: 0.75rem 0.25rem;
    }
  }

    @media (max-width: 350px) {
    width: 1.8rem;
    height: 2.2rem;
    input {
      padding: 0.6rem 0.15rem;
    }
  }
`;
