const keyName = 'flowpay_lead';
const expire = 5 * 60 * 1000;

export function getItem() {
  let ls: any = localStorage.getItem(keyName);
  if (ls) {
    ls = JSON.parse(ls);
    if (ls.expiresAt && Date.now() < ls.expiresAt) return ls.body;
    return null;
  }

  return null;
}

export function setItem(data: unknown) {
  const toStore = { body: data, expiresAt: Date.now() + expire };
  localStorage.setItem(keyName, JSON.stringify(toStore));
}

export function removeItem() {
  localStorage.removeItem(keyName);
}
