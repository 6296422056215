// In PartnerRoutes.tsx

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PartnerOverview from '../components/Pages/Partner/PartnerOverview';
import PartnerFinancing from '../components/Pages/Partner/PartnerFinancing';
import PartnerCommissions from '../components/Pages/Partner/PartnerCommissions';
import Settings from '../components/Pages/Settings';
import PageNotFound from '../components/Pages/PageNotFound';
import { ExternalUserDto, PartnerLoansOverviewDto } from '../types/api';

interface PartnerRoutesProps {
  customerId: string;
  currentUser: ExternalUserDto;
  partnerOverview: PartnerLoansOverviewDto | null;
}

const PartnerRoutes: React.FC<PartnerRoutesProps> = ({
  customerId,
  currentUser,
  partnerOverview,
}) => {
  return (
    <Routes>
      <Route
        path="/overview"
        element={
          <PartnerOverview
            currentUser={currentUser}
            partnerOverview={partnerOverview!}
          />
        }
      />

      <Route
        path="/financing"
        element={<PartnerFinancing customerId={customerId} />}
      />
      <Route
        path="/commissions"
        element={
          <PartnerCommissions
            customerId={customerId}
            partnerOverview={partnerOverview!}
          />
        }
      />

      <Route
        path="/settings"
        element={<Settings customerId={customerId} currentUser={currentUser} />}
      />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default PartnerRoutes;
