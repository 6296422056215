import DialogContent from '@mui/material/DialogContent';

import Dialog from '../UI/Dialog';
import styles from './PdfDialog.module.scss';
import { FC } from 'react';

interface IPdfDialogProps {
  open: boolean;
  pdfSrc?: string;
  title: string;
  onClose: () => void;
}

const PdfDialog: FC<IPdfDialogProps> = ({ onClose, open, pdfSrc, title }) => {
  return (
    <Dialog
      open={open}
      handleClose={() => onClose()}
      title={title || ''}
      customStyles={{ width: '90%', maxWidth: '900px' }}
    >
      <DialogContent>
        <div className={styles['dialog-wrapper']}>
          <iframe src={pdfSrc} title={title || ''} width="100%" height="100%" />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PdfDialog;
