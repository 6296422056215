import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExternalUserDto } from '../../types/api';
import { RootState } from '../../store';

// Define a type for the slice state
interface ICurrentUserState {
  currentUser: ExternalUserDto | null;
}

const initialState: ICurrentUserState = {
  currentUser: null,
};

const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setCurrentUserAction: (state, action: PayloadAction<ExternalUserDto>) => {
      state.currentUser = action.payload;
    },
  },
});

export const { setCurrentUserAction } = currentUserSlice.actions;
export const selectCurrentUser = (state: RootState) => state.currentUser;

export default currentUserSlice.reducer;
