import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../store';

interface IDialog {
  id: number;
  component: unknown;
  props?: Record<string, unknown>;
}

interface DialogState {
  dialogs: IDialog[];
}

const initialState: DialogState = {
  dialogs: [],
};

const dialogSlice = createSlice({
  name: 'dialogs',
  initialState,
  reducers: {
    addDialog: (state, action: PayloadAction<IDialog>) => {
      state.dialogs.push(action.payload);
    },
    removeDialog: (state, action: PayloadAction<number>) => {
      state.dialogs = state.dialogs.filter(
        (dialog) => dialog.id !== action.payload
      );
    },
  },
});

export const { addDialog, removeDialog } = dialogSlice.actions;
export const selectDialogs = (state: RootState) => state.dialogs.dialogs;
export default dialogSlice.reducer;
