import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExternalUserDtoCustomerDto, IsoCurrencyCode } from '../../types/api';
import { RootState } from '../../store';

interface ICurrentCustomerState {
  currentCustomer: ExternalUserDtoCustomerDto | null;
  defaultCurrency: IsoCurrencyCode | null;
}

const initialState: ICurrentCustomerState = {
  currentCustomer: null,
  defaultCurrency: null,
};

const currentCustomerSlice = createSlice({
  name: 'currentCusutomer',
  initialState,
  reducers: {
    setCurrentCustomerAction: (
      state,
      action: PayloadAction<ExternalUserDtoCustomerDto>
    ) => {
      state.currentCustomer = action.payload;
    },
    setDefaultCurrencyAction: (
      state,
      action: PayloadAction<IsoCurrencyCode>
    ) => {
      state.defaultCurrency = action.payload;
    },
  },
});

export const { setCurrentCustomerAction, setDefaultCurrencyAction } =
  currentCustomerSlice.actions;
export const selectCurrentCustomer = (state: RootState) =>
  state.currentCustomer;

export default currentCustomerSlice.reducer;
