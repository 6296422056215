import { useTranslation } from 'react-i18next';

import styles from './PartnerFilter.module.scss';
import { FC } from 'react';
import { ExternalUserDto } from '../../../types/api';

interface IFilterProps {
  currentUser: ExternalUserDto;
}

const Filter: FC<IFilterProps> = ({ currentUser }) => {
  const { t } = useTranslation();

  return (
    <div className={styles['filter-wrapper']}>
      <div className={styles.calendar}>
        <label>{t('partner-overview-last-year')}</label>
      </div>
      <div className={styles.client}>
        <img
          src={require(`../../../assets/partners/${currentUser.partner?.code}.svg`)}
          title={currentUser.partner?.name}
          alt={currentUser.partner?.name}
        />
      </div>
    </div>
  );
};

export default Filter;
