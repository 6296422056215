import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SumsubWebSdk from '@sumsub/websdk-react';

import { useLoading } from '../../../context/loading-context';
import {
  TCustomError,
  useErrorHandler,
} from '../../../context/error-handler-context';
import axios from '../../../utils/axios';

import Button from '../../UI/Button';

import styles from './Application.module.scss';
import i18n from '../../../i18n/config';

interface IApplicationKycProps {
  applicationId: string;
  repId: string;
  isRep2: boolean;
  handleKycFinish: (repId: string) => void;
}

const ApplicationKyc: FC<IApplicationKycProps> = ({
  applicationId,
  handleKycFinish,
  repId,
}) => {
  const { t } = useTranslation();

  const { setLoading } = useLoading();
  const { setError } = useErrorHandler();

  const [kycToken, setKycToken] = useState<string | null>(null);

  const onClick = () => {
    setLoading(true);
    axios
      .post(`applications/${applicationId}/step/kyc/start/person/${repId}`, {})
      .then((res) => {
        setKycToken(res.data.kycToken);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };

  const sumsubExpirationHandler = async () => {
    return axios
      .post(`applications/${applicationId}/step/kyc/start/person/${repId}`, {})
      .then((res) => res.data.kycToken);
  };

  const sumsubMessageHandler = (
    type: string,
    messageStatus: Record<string, any>
  ) => {
    if (type === 'idCheck.onApplicantStatusChanged') {
      console.log(messageStatus);
    }

    const finalLevels = ['basic-kyc-level-2.0', 'NL-2-passport-or-drivinglicence-2', 'NL-2-drivinglicence-insurance'];
    if (messageStatus && messageStatus.levelName && messageStatus.reviewStatus && 
      (messageStatus.reviewStatus === 'completed') && 
      finalLevels.includes(messageStatus.levelName))
    {
      const retry =
        messageStatus.reviewResult.reviewAnswer === 'RED' &&
        messageStatus.reviewResult.reviewRejectType === 'RETRY';

      if (!retry) {
        handleKycFinish(repId);
      }
    }
  };

  const sumsubErrorHandler = (error: TCustomError) => {
    setError(error);
  };

  return (
    <div>
      <div className="gradient info">
        <h1>
          <span>{t('application-kyc-title')}</span>
        </h1>
      </div>

      <div className={styles['body']}>
        {kycToken && (
          <SumsubWebSdk
            accessToken={kycToken}
            expirationHandler={sumsubExpirationHandler}
            config={{
              uiConf: {
                customCssStr:
                  '.step .title {color: #ffffff} .step.active .title {color: #ffffff} :root {--gray-color: #c74fff; --red-color: #c74fff; --orange-color: #c74fff;} button.blue {background: #c74fff}',
              },
              lang: i18n.language,
            }}
            options={{
              addViewportTag: false,
              adaptIframeHeight: true,
            }}
            onMessage={sumsubMessageHandler}
            onError={sumsubErrorHandler}
          />
        )}

        {!kycToken && (
          <>
            <p>{t('application-kyc-txt')}</p>
            <div className={styles.buttons}>
              <Button
                className="application"
                onClick={onClick}
                id="app_KYC_start"
              >
                {t('application-button-continue')}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ApplicationKyc;
