import axios, { AxiosRequestConfig } from 'axios';
import i18n from './../i18n/config';
import { sec } from './security';

const baseURL = process.env.REACT_APP_BASE_URL;
const audience = process.env.REACT_APP_API_AUDIENCE;

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  skipLang?: boolean;
  skipAuthToken?: boolean;
}

const instance = axios.create({
  baseURL: baseURL,
});

instance.interceptors.request.use(
  async (config: CustomAxiosRequestConfig) => {
    config.headers = config.headers ?? {};

    if (!config.skipLang) {
      config.headers['x-fp-lang'] = i18n.language;
    }

    if (!config.skipAuthToken) {
      const accessToken = await sec.getAccessTokenSilently()({ audience });
      if (!accessToken) {
        console.error('Access token is invalid or not found');
        return Promise.reject('Access token is invalid or not found');
      }
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export default instance;
